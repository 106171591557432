const { Space, Button } = require("antd")
const {SaveOutlined } = require("@ant-design/icons")
const BaseToolBarDetail = (props) => {
    const save = () => {
        if (props.save) props.save();
    }
    const cancel = () => {
        if (props.cancel) props.cancel();
    }
    return <div className="toolbar-box-detail">
        <Space>
            <Button icon={<SaveOutlined/>} onClick={save}>Save</Button>
            <Button onClick={cancel}>Cancel</Button>
        </Space>
    </div>
}
export default BaseToolBarDetail;