import { getSigner, getSmartContract } from 'smart-contract/common'
import utilsConfig from 'smart-contract/abi/TSUtils.json'
import { getContractByCode } from 'utils/common'
import { ethers } from 'ethers';
import { SmartContractCode } from 'utils/constants'

const getUtilsContract = async () => {
  const chainId = await window.ethereum.request({ method: 'eth_chainId' });
  const addressUtils = getContractByCode(SmartContractCode.UTILS_CONTRACT,chainId);

  return getSmartContract(addressUtils, utilsConfig.abi, getSigner())
}

export const getBlockVoteInfo = async (governorAddress, proposalId) => {
  try {
    const contract = await getUtilsContract();
    const rs = await contract.getBlockVoteInfo(governorAddress, proposalId);
    return rs;
  } catch (err) {
    console.log(err)
  }
  return null;
}
export const getTokenFee = async (tssaleAddress, token) => {
  try {
    const contract = await getUtilsContract();
    const rs = await contract.getTokenFee(tssaleAddress, token);
    return rs;
  } catch (err) {
    console.log(err)
  }
  return null;
}
export const getDisburmentInfo = async (saleId) => {
  try {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const addressSale = getContractByCode(SmartContractCode.SALE_CONTRACT,chainId);
    const contract = await getUtilsContract();
    const rs = await contract.getDisburmentInfo(addressSale, saleId);
    const totalDisburment = parseFloat(ethers.utils.formatEther(rs.totalDisburment));
    const totalRaise = parseFloat(ethers.utils.formatEther(rs.totalRaise));
    return {
      totalRaise,
      totalDisburment
    };
  } catch (err) {
    console.log(err)
  }
  return null;
}

