/** @format */

import axios from "axios";
import store from "redux/stores";
import { getCookie } from "./common";
import { ACCESSTOKEN } from "./constants";
const customAxios = axios.create();

customAxios.interceptors.request.use(
  async (config) => {
    const accessToken = getCookie(ACCESSTOKEN);
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
    }
    const response = {
      code: 0,
      data: error?.response?.data,
    };

    throw response;
  }
);
export { customAxios };
