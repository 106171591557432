import { LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Menu, message } from 'antd';
import { getServerTime } from 'apis/apiauth';
import WalletList from 'components/common/connect-wallet/WalletList';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connected, disconnect } from 'redux/reducers/connectWalletSlice';
import { getCurrentProvider } from 'utils/common';
import { SwitchChainModal } from './SwitchChainModal';

function ConnectWallet() {
    const address = useSelector(state => state.connectWalletSlice.address);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    function menuClick({ key }) {
        switch (key) {
            case "disconnect":
                dispatch(disconnect());
                break;
            default:
                break;
        }
    }
    const menu = <Menu onClick={menuClick} items={
        [{
            label: "Disconnect",
            key: 'disconnect',
            icon: <LogoutOutlined />
        }]
    } />
    function displayWallet(walletId) {
        return walletId
            ? walletId.substring(0, 5) +
            "..." +
            walletId.substring(
                walletId.length - 5,
                walletId.length
            )
            : "";
    }
    function setAccountAddress(address) {
        dispatch(connected({ address }))
    }

    function onWalletListClosed(walletId) {
        if (walletId === "metamask") {
            connectMetaMask();
        }
        else if (walletId === "walletconnect") {

        }
        setVisible(false);
    }
    async function connectMetaMask() {
        const provider = getCurrentProvider();
        var addresses = null;
        try {
            addresses = await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            var rsDateTime = await getServerTime();
            const timestamp = rsDateTime.data.Timestamp;
            const hashmessage = timestamp + "#" + "abc";
            await signer.signMessage(hashmessage);
            // const rs = await auth({ address: addresses[0], message: hashmessage, signature: signature });
            // setCookie(ACCESSTOKEN, rs.data.Data.AccessToken);
        } catch (error) {
            message.warning(error.message);
        }
        if (addresses && addresses.length > 0) {
            setAccountAddress(addresses[0]);
        }
    }
    return <>
        {address === "" ?
            <div className='connectwallet-btn' onClick={() => { setVisible(true) }}>Connect wallet</div>
            : <Dropdown overlay={menu} trigger={['click']}>
                <div className='connectwallet-btn'>{displayWallet(address)}</div>
            </Dropdown>}

        {visible ? <WalletList visible={visible} onClosed={onWalletListClosed} /> : null}
        <SwitchChainModal/>
    </>
}
export default ConnectWallet