import {
    EditOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Space, Spin, Table } from 'antd';
import { changePassword, createUser, filterAccounts, updateUser } from 'apis/apiaccounts';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setData, setLoading } from 'redux/reducers/accountSlice';

const Account = () => {
    const dispatch = useDispatch();
    const accounts = useSelector(state => state.accountSlice.accounts);
    const loading = useSelector(state => state.accountSlice.loading);
    const [ acceptRowIndex, setAcceptRowIndex ] = useState(-1);
    const [ openUserCreateModal, setOpenUserCreateModal ] = useState(false);
    const [ openChangePasswordModal, setOpenChangePasswordModal ] = useState(false);
    const [ openUpdateUserModal, setOpenUpdateUserdModal ] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        try {
            dispatch(setLoading(true));
            const rs = await filterAccounts();
            dispatch(setLoading(false));
            const orderedUsers = rs.data.Data?.map((item, idx) => ({ ...item, index: idx + 1 }));
            dispatch(setData(orderedUsers));
        } catch (error) {
            const errorMessage = error?.response?.statusText || "Something went wrong";
            message.error(errorMessage);
        }
    }

    const onCloseChangePasswordModal = () => {
        setAcceptRowIndex(-1);
        setOpenChangePasswordModal(false);
    }

    const onCloseUpdateUserModal = () => {
        setAcceptRowIndex(-1);
        setOpenUpdateUserdModal(false);
    }

    const onChangePassword = async (row, values) => {
        try {
            const request = {
                username: row.username,
                currentPassword: values.currentPassword,
                newPassword: values.newPassword
            };
            await changePassword(request);
            message.success("Change password successfully");
            onCloseChangePasswordModal();
        } catch (error) {
            const errorMessage = error?.response?.statusText || "Something went wrong";
            message.error(errorMessage);
        }
    }

    const onUpdateUser = async (row, values) => {
        try {
            const request = {
                currentUsername: row?.username,
                newUsername: values?.newUsername
            };
            await updateUser(request);
            message.success("Update user successfully");
            onCloseUpdateUserModal();
        } catch (error) {
            const errorMessage = error?.response?.statusText || "Something went wrong";
            message.error(errorMessage);
        }
    }

    const onCreateUser = async (values) => {
        try {
            const request = {
                username: values.username,
                password: values.password
            };
            await createUser(request);
            message.success("Create user successfully");
            loadData();
        } catch (error) {
            const errorMessage = error?.response?.statusText || "Something went wrong";
            message.error(errorMessage);
        } finally {
            setOpenUserCreateModal(false);
        }
    }

    const renderCreateUserModal = () => {
        if (!openUserCreateModal) {
            return null;
        }

        return (
            <Modal visible onCancel={() => setOpenUserCreateModal(false)} footer={null}>
                <Form
                    initialValues={{ registerBlock: 100, voteBlock: 100 }}
                    layout="vertical"
                    onFinish={(values) => {
                        onCreateUser(values)
                    }}
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input password!',
                            },
                            {
                                min: 6,
                                message: 'Username must be at least 6 characters!'
                            }
                        ]}
                    >
                        <Input type='text' style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Please confirm password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Row justify="end">
                        <Space size="large">
                            <Button htmlType="submit" type='primary'>Accept</Button>
                            <Button onClick={() => setOpenUserCreateModal(false)} danger>Cancel</Button>
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )

    }

    const renderChangePasswordModal = () => {
        if (!openChangePasswordModal) {
            return null;
        }
        const row = accounts[acceptRowIndex];
        return (
            <Modal visible onCancel={onCloseChangePasswordModal} footer={null}>
                <Form
                    initialValues={{ registerBlock: 100, voteBlock: 100 }}
                    layout="vertical"
                    onFinish={(values) => {
                        onChangePassword(row, values)
                    }}
                >
                    <Form.Item
                        name="currentPassword"
                        label="Current password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message: 'Password must be at least 6 characters!'
                            }
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                min: 6,
                                message: 'Password must be at least 6 characters!'
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            {
                                min: 6,
                                message: 'Password must be at least 6 characters!'
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                },
                            }),
                            ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Row justify="end">
                        <Space size="large">
                            <Button htmlType="submit" type='primary'>Accept</Button>
                            <Button onClick={onCloseChangePasswordModal} danger>Cancel</Button>
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    }

    const renderUpdateUserModal = () => {
        if (!openUpdateUserModal) {
            return null;
        }

        const row = accounts[acceptRowIndex];

        return (
            <Modal visible onCancel={onCloseUpdateUserModal} footer={null}>
                <Form
                    initialValues={{ registerBlock: 100, voteBlock: 100 }}
                    layout="vertical"
                    onFinish={(values) => {
                        onUpdateUser(row, values)
                    }}
                >
                    <Form.Item
                        name="newUsername"
                        label="Username"
                        initialValue={row?.username || ""}
                        rules={[
                            {
                                required: true,
                                message: 'Please input username!',
                            },
                            {
                                min: 6,
                                message: 'Username must be at least 6 characters!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Row justify="end">
                        <Space size="large">
                            <Button htmlType="submit" type='primary'>Accept</Button>
                            <Button onClick={onCloseUpdateUserModal} danger>Cancel</Button>
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index',
            width: '10%'
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            width: '40%'
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            width: '50%',
            render: (_, record, rowIndex) => (
                <Space>
                    <Button
                        onClick={() => { setAcceptRowIndex(rowIndex); setOpenChangePasswordModal(true)}}
                        type='primary'
                        icon={<EditOutlined />}
                    >
                        Change password
                    </Button>
                    <Button
                        onClick={() => { setAcceptRowIndex(rowIndex); setOpenUpdateUserdModal(true)}}
                        type='primary'
                        icon={<EditOutlined />}
                    >Change username</Button>
                </Space>
            ),
        }
    ];

    return <div className='box-list'>
        <div style={{ flex: 1, borderLeft:'1px solid #ddd' }}>
            <div className='flex-scroll-wrapper'>
                <div className='flex-scroll-content'>
                    <Spin spinning={loading}>
                        <Row justify='end' style={{ margin: '0.5em' }}>
                                <Col>
                                    <Button type='primary' onClick={() => setOpenUserCreateModal(true)}>Create user</Button>
                                </Col>
                        </Row>
                        <Table rowKey="accounts" loading={loading} columns={columns} dataSource={accounts} pagination={false}>
                    </Table>
                    </Spin>
                </div>
                {/* <BasePagination
                    defaultCurrent={1}
                    total={500}
                    pageSize={10}
                    showSizeChanger={false}
                /> */}
            </div>
        </div>
        {renderChangePasswordModal()}
        {renderCreateUserModal()}
        {renderUpdateUserModal()}
    </div>
}

export default Account;