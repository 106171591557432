import { createSlice } from '@reduxjs/toolkit';
export const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects:[],
    loading:true,
    busy: false
  },
  reducers: {
    setData: (state, action) => {
      state.projects = action.payload;
    },
    setLoading:(state,action)=>{
        state.loading = action.payload
    },
    setBusy:(state,action)=>{
        state.busy = action.payload
    }
  },
});

export const { setData,setLoading,setBusy } = projectSlice.actions;

export const projects = (state) => state.projects; // What is that ? and why is it state.user and then a third user Object ???
export const loading = (state) => state.loading; // What is that ? and why is it state.user and then a third user Object ???
export const busy = (state) => state.busy; // What is that ? and why is it state.user and then a third user Object ???

export default projectSlice.reducer;