import axios from 'axios';
import { getAdminApiUrl, getApiUrl, getAuthorizationHeader } from 'utils/common';

export function filterAccounts(data) {
    try {
        var url = getAdminApiUrl() + '/users/auth/filter';
        const config = getAuthorizationHeader();
        return axios.post(url, data, config)
    } catch (error) {
        throw error;
    }
}

export function changePassword(request) {
    try {
        var url = getAdminApiUrl() + '/users/auth/change-password';
        const config = getAuthorizationHeader();
        return axios.put(url, request, config)
    } catch (error) {
        throw error;
    }
}

export function createUser(request) {
    try {
        var url = getAdminApiUrl() + '/users/auth/create';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {
        throw error;
    }
}

export function updateUser(request) {
    try {
        var url = getAdminApiUrl() + '/auth/users';
        const config = getAuthorizationHeader();
        return axios.put(url, request, config);
    } catch (error) {
        throw error;
    }
}