import { Button, message, Popconfirm, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux"
import { getPools } from 'apis/apipool'
import { summarySale } from 'smart-contract/saleContract'
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
import { CheckOutlined} from '@ant-design/icons';
import moment from 'moment';
const { Column } = Table;
const Summary = () => {
    const [pools, setPools] = useState([]);
    const [loading, setLoading] = useState(false);
    const [busy, setBusy] = useState(false);
    const dispatch = useDispatch();
    const loadData = async () => {
        setLoading(true)
        var rs = await getPools({ pageIndex: 1, pageSize: 1000 });
        var arr = rs?.data?.Data;
        if (!arr)
            arr = [];
        else
            arr = arr.filter(x => x.SmartID != null);
        setLoading(false);
        setPools(arr);
    }
    const converDateTime = (timeStamp) => {
        const date = new moment.unix(timeStamp);
        return date.format("DD/MM/YYYY HH:mm:ss");
    }
    const checkChangeBeforeSubmit = async (chainId) => {
        var result = false;
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId && chainId) {
            return (currentChainId === chainId)
        }
        return result;
    }

    const summary = async (record) => {
        debugger
        const validChain = await checkChangeBeforeSubmit(record.Chain)
        if (!validChain) {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch(updateSwitchChainId({
                chainId: record.Chain,
                currentChainId:currentChainId,
                title: 'Please switch chain of buidl',
                description: 'You need to switch chain to continue'
            }))
            return;
        }
        setBusy(true);
        try {
            await summarySale(record.SmartID, record.Chain);
            message.success("Summary success.");
        } catch (err) {
            message.error(err.message)
        }
        setBusy(false);
    }
    useEffect(() => {
        loadData();
    }, []);

    return <>
        <Spin spinning={busy}>
            <Table dataSource={pools} loading={loading}>
                <Column width={100} title="Image" dataIndex="Avatar" key="Avatar" render={(image) => <div style={{ width: 80, height: 60, overflow: "hidden" }}><img style={{ height: 60 }} alt='project image' src={image} /></div>} />
                <Column title="Pool Name" dataIndex="NameInvestment" key="NameInvestment" />
                <Column width={200} title="Start time" dataIndex="StartTime" key="StartTime" render={(val) => converDateTime(val)} />
                <Column width={200} title="End time" dataIndex="EndTime" key="EndTime" render={(val) => converDateTime(val)} />
                <Column width={120} title="Action" dataIndex="action" key="action" render={(text, record) => <>
                    <Popconfirm title="Are you sure want to summary?"
                        onConfirm={() => summary(record)}
                        okText="Yes"
                        cancelText="No">
                        <Button type="link" icon={<CheckOutlined style={{ color: "green" }} />}>Summary</Button>
                    </Popconfirm>
                </>} />
            </Table>
        </Spin>
    </>
}
export default Summary