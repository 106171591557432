import httpClient from './httpClient';

export function getRoles() {
    try {
        const response = httpClient.getWithAuthorization(`/admin/roles/auth/get-list`);
        return response;
    } catch (error) {
        throw error;
    }
}

export function createRoles(request) {
    try {
        return httpClient.postWithAuthorization('/admin/roles/auth/create', request);
    } catch (error) {
        throw error;
    }
}

export function deleteRole(role) {
    try {
        return httpClient.deleteWithAuthorization(`/admin/roles/auth/${role.id}`);
    } catch (error) {
        throw error;
    }
}
