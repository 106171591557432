import { CheckOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Popconfirm, Row, Space, Table } from "antd";
import { upsertResourceOperation } from 'apis/apiresourceoperation';
import { deleteResource, getResources, upsertResource } from "apis/apiresources";
import { EditableCell, EditableRow } from 'components/common/table/EditableTableComponents';
import BaseToolBar from 'components/common/tool-bar/BaseToolBar';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { abilityActions, resourceNames } from 'utils/constants';
const { PlusOutlined } = require("@ant-design/icons")
  
const ResourcePage = () => {
    const navigate = useNavigate();
    const resourceName = resourceNames.resources;
    const [loading, setLoading] = useState(false);
    const [ resources, setResources ] = useState([]);
    const [ masterData, setMasterData ] = useState([]);
    const [ isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetchResource();
    }, []);

    const fetchResource = async () => {
        try {
            const response = await getResources();
            const resourceWithKeys = response?.data?.Data.map((item, index) => ({ ...item, key: index}))
            setResources(resourceWithKeys);
            setMasterData(response?.data?.Data);
        } catch(error) {

        }
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    };

    const onCreateResource = async (values) => {
        try {
            const request = {
                name: values.name,
                code: values.code,
                operations: []
            }
            await upsertResource(request);
            fetchResource()
            hideModal();
        } catch (error) {

        }
    }

    const addNew = (row) => {
        const newData = [...resources];
        const rowIndex = newData.findIndex(el => el.id === row.id);
        const newOperation = {
            name: "",
            uri: "",
            method: "GET"
        };
        newData[rowIndex].operations = [
            ...newData[rowIndex].operations,
            newOperation
        ];
        setResources(newData);
    }

    const handleDeleteResource = async (row) => {
        try {
            await deleteResource(row.id);
            fetchResource();
        } catch (error) {

        }
    }

    const columns = [
        {
            title: "Resource name",
            key: "name",
            dataIndex: 'name',
        },
        {
            title: "Action",
            render: (row) => {
                return (
                    <div>
                        <Button icon={<PlusOutlined />} onClick={() => addNew(row)}></Button>
                        <Button
                            title="Delete"
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            style={{ margin: "0 10px" }}
                            type="link"
                            onClick={() => handleDeleteResource(row)}
                            ></Button>
                    </div>
                )
            }
        }
    ];

    const expandRowsRender = (row) => {
        const handleSaveResourceOperation = async (item) => {
            try {
                const roleResouce = { ...row };
                const request = {
                    ...item,
                    resourceName: row.name
                };
                const response = await upsertResourceOperation(request);
                if (item?.id) {
                    const operationIndex = roleResouce.operations?.findIndex(el => el.id === item.id);
                    const operations = roleResouce.operations.filter(item => item.id);
                    operations[operationIndex] = response.data?.Data;
                    roleResouce.operations = operations;
                    await upsertResource(roleResouce);
                } else {
                    roleResouce.operations = roleResouce.operations.filter(el => el.id);
                    roleResouce.operations = [ ...roleResouce.operations, response.data?.Data ];
                    await upsertResource(roleResouce);
                }
            } catch (error) {

            }
        }

        const handleDeleteResource = async (item) => {
            try {
                const index = row.operations?.findIndex(el => el.id === item?.id);
                row.operations.splice(index, 1);
                await upsertResource(row);
            } catch (error) {

            }
        }

        const handleCancelAction = (item) => {
            const newResources = [ ...resources ];
            if (item?.id) {
                const index = resources?.findIndex(el => el.id === row.id);
                const operationIndex = newResources[index].operations?.findIndex(el => el.id === item?.id);
                newResources[index].operations[operationIndex] = masterData[index].operations[operationIndex];
                setResources(newResources);
                return;
            }

            newResources?.operations?.slice(0, -1);
            setResources(newResources);
        }


        const handleSave = (item) => {
            const newData = [...resources];
            const rowIndex = newData.findIndex(el => el.id === row.id);
            const operationIndex = newData[rowIndex].operations.findIndex(el => el.id === item?.id);
            newData[rowIndex].operations[operationIndex] = { ...item };
            setResources(newData);
        };

        const defaultColumns = [
            { title: 'Name', dataIndex: 'name', key: 'name', editable: true, },
            { title: 'Code', dataIndex: 'code', key: 'code', editable: true, },
            { title: 'Action', dataIndex: 'action', key: 'action', editable: true, },
            { title: 'URI', dataIndex: 'uri', key: 'uri', editable: true },
            { title: 'Method', dataIndex: 'method', key: 'method', editable: true },
            {
                title: "Actions",
                render: (row) => {
                    return (
                        <Space>
                            <Popconfirm title="Are you sure want to approve?"
                                onConfirm={() => handleSaveResourceOperation(row)}
                                okText="Yes"
                                cancelText="No">
                                <Button type="link" icon={<CheckOutlined style={{ color: "green" }} />}>Save</Button>
                            </Popconfirm>
                            <Button
                                type="link"
                                icon={<StopOutlined style={{ color: "red" }} />}
                                onClick={() => handleCancelAction(row)}
                            >Cancel</Button>
                            <Popconfirm title="Are you sure want to delete?"
                                onConfirm={() => handleDeleteResource(row)}
                                okText="Yes"
                                cancelText="No">
                                <Button type="link" icon={<DeleteOutlined style={{ color: "red" }} />}>Delete</Button>
                            </Popconfirm>
                        </Space>
                    )
                },
            }
        ];

        const columns = defaultColumns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave
              }),
            };
        });

        const components = {
            body: {
              cell: EditableCell,
              row: EditableRow,
            },
        };

        return (
            <Table
                columns={columns}
                dataSource={[...row?.operations]}
                pagination={false}
                components={components}
            />
        )
    };

    return (
      <>
        <div className='flex-scroll-wrapper'>
            <div className='flex-scroll-content'>
                <BaseToolBar addNew={showModal} resourceName={resourceNames.resources} />
                <Table
                    dataSource={resources}
                    columns={columns}
                    loading={loading}
                    expandedRowRender={expandRowsRender}
                    // scroll={scroll}
                />
            </div>
        </div>
        <Modal title="Add resource" visible={isModalOpen} onCancel={hideModal} footer={null}>
            <Form
                initialValues={{ registerBlock: 100, voteBlock: 100 }}
                layout="vertical"
                onFinish={(values) => {
                    onCreateResource(values)
                }}
            >
                <Form.Item
                    name="name"
                    label="Resource name"
                    rules={[{ required: true, message: "Please input your resource name!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="code"
                    label="Resource code"
                    rules={[{ required: true, message: "Please input your resource code!" }]}
                >
                    <Input />
                </Form.Item>

                <Row justify="end">
                    <Space size="large">
                        <Button htmlType="submit" type='primary'>Save</Button>
                        <Button onClick={hideModal} danger>Cancel</Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
      </>
    );
  };
  export default ResourcePage;
  