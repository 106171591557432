import {
    CheckOutlined,
    StopOutlined
} from '@ant-design/icons';
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getProject, updateProject } from "apis/apiproject";
import { Button, Space, Table, Popconfirm, Spin, message } from 'antd'
import { setData, setBusy, setLoading } from "redux/reducers/projectSlice"
import { approveProject } from "smart-contract/projectContract"
import chains from 'utils/chains.json'
import { Can } from 'config/Can';
import BaseFilter from 'components/common/controls/BaseFilter';
import { filterParamTypes, resourceNames } from 'utils/constants';
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
// import BaseFilter from 'components/common/controls/BaseFilter'
// import BasePagination from 'components/common/pagination/BasePagination'
const { Column } = Table;
const ApproveProject = () => {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projectSlice.projects)
    const loading = useSelector(state => state.projectSlice.loading)
    const busy = useSelector(state => state.projectSlice.busy)
    const initialFilterParam = { status: 1, pageIndex: 1, pageSize: 100 };

    const filterParams = [
        { checked: false, columnName: "projectName", columnType: 0, title: "Project Name", type: filterParamTypes.TEXT },
        { checked: false, columnName: "chain", columnType: 0, title: "Chain", type: filterParamTypes.TEXT },
        { checked: false, columnName: "cDate", columnType: 0, title: "Created Date", type: filterParamTypes.DATE },
    ];

    useEffect(() => {
        loadData(initialFilterParam);
    }, []);

    const loadData = async (filterParams) => {
        try {
            dispatch(setLoading(true));
            const rs = await getProject(filterParams);
            dispatch(setData(rs.data.Data));
        } catch(error) {

        } finally {
            dispatch(setBusy(false));
        }
    }

    const getChainName = (chainId) => {
        const chain = chains.find(x => x.chainId === chainId);
        return chain ? chain.chainName : chainId;
    }
    const checkChainBeforeSubmit = async (chainId) => {
        var result = false;
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId && chainId) {
            return (currentChainId === chainId)
        }
        return result;
    }

    const confirmApprove = async (record) => {
        const validChain = await checkChainBeforeSubmit(record.Chain)
        if (!validChain) {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch(updateSwitchChainId({
                chainId: record.Chain,
                currentChainId:currentChainId,
                title: 'Please switch chain of buidl',
                description: 'You need to switch chain to continue'
            }))
            return;
        }
        try{
            if (!record.SmartID) {
                message.warning("Project has no smartid ")
                return;
            }
            dispatch(setBusy(true));
            await approveProject(record.SmartID, record.Chain);
            message.success("Approve project success");
            console.log(record);
            dispatch(setBusy(false));
        }catch(err){
            console.log(err);
            message(err.message);
        }
    }
    const confirmReject = async (record) => {
        dispatch(setBusy(true));
        await updateProject({ ProjectID: record.ProjectID, Status: 3 });
        console.log(record);
        dispatch(setBusy(false));
    }
    return <div className='box-list'>
        <BaseFilter filterParams={filterParams} onFilter={loadData} />
        <div style={{ flex: 1, borderLeft:'1px solid #ddd' }}>
            <div className='flex-scroll-wrapper'>
                <div className='flex-scroll-content'>
                    <Spin spinning={busy}>
                        <Table dataSource={projects} loading={busy} rowKey="ProjectID" >
                            <Column width={100} title="Image" dataIndex="Avatar" key="Avatar" render={(image) => <div style={{ width: 80, height: 60, overflow: "hidden" }}><img style={{ height: 60 }} alt='project image' src={image} /></div>} />
                            <Column title="Project Name" dataIndex="ProjectName" key="ProjectName" />
                            <Column width={250} title="Chain" dataIndex="Chain" key="Chain" render={(chainId) => getChainName(chainId)} />
                            {/* <Column title="Description" dataIndex="ShortDescription" key="ShortDescription" /> */}
                            <Column width={120} title="Action" dataIndex="action" key="action" render={(_, record) => <>
                                <Space>
                                    <Can I="approve" a={resourceNames.approveProject}>
                                        <Popconfirm title="Are you sure want to approve?"
                                            onConfirm={() => confirmApprove(record)}
                                            okText="Yes"
                                            cancelText="No">
                                                <Button type="link" icon={<CheckOutlined style={{ color: "green" }} />}>Approve</Button>
                                        </Popconfirm>
                                    </Can>

                                    <Popconfirm title="Are you sure want to reject?"
                                        onConfirm={() => confirmReject(record)}
                                        okText="Yes"
                                        cancelText="No">
                                        <Can I="reject" a={resourceNames.approveProject}>
                                            <Button type="link" icon={<StopOutlined style={{ color: "red" }} />}>Reject</Button>
                                        </Can>
                                    </Popconfirm>
                                </Space>
                            </>} />
                        </Table>
                    </Spin>
                </div>
                {/* <BasePagination defaultCurrent={1} total={500} pageSize={10} showSizeChanger={false} /> */}
            </div>
        </div>

    </div>
}
export default ApproveProject