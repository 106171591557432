/** @format */

import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { Button, Col, Row, Select, Table } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import API from "apis/apiconfig";
import { useNavigate } from "react-router-dom";
import VentureModal from "./modal";
import BaseToolBar from "components/common/tool-bar/BaseToolBar";
import { abilityActions, resourceNames } from "utils/constants";
import { Can } from "config/Can";
const { Option } = Select;
const VentureCapitalTypes = () => {
  const resourceName = resourceNames.ventureCapitalTypes;
  const navigate = useNavigate();
  const optionName = "venture-capital-types";
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const getDataOption = async () => {
    const body = {
      pageIndex: currentPage,
      pageSize: 10,
    };
    setLoading(true);
    const res = await API.getOptionByType(optionName, body);
    setTotalRecord(res.TotalRecords);
    setListData(res.Data);
    setLoading(false);
  };
  useEffect(() => {
    getDataOption();
  }, []);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Action",
      width: 140,
      render: (render, record) => (
        <div>
          <Can I={abilityActions.update} a={resourceName}>
            <Button
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => navigate("/" + optionName + "/" + record.id)}
              style={{ margin: "0 10px" }}
              type="link"
            ></Button>
          </Can>
          <Can I={abilityActions.delete} a={resourceName}>
            <Button
              title="Delete"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              style={{ margin: "0 10px" }}
              type="link"
            ></Button>
          </Can>
        </div>
      ),
    },
  ];
  const handlePagination = (event) => {
    setCurrentPage(event);
  };
  const refresh = () => {
    setCurrentPage(1);
    getDataOption();
  };
  const addNew = () => {
    navigate(`/${optionName}/add-new`);
  };
  return (
    <>
      <BaseToolBar resourceName={resourceName} addNew={addNew} refresh={refresh} />
      <Table
        dataSource={listData}
        columns={columns}
        loading={loading}
        pagination={{
          onChange: handlePagination,
          pageSize: 10,
          total: totalRecord,
        }}
      />
    </>
  );
};
export default VentureCapitalTypes;
