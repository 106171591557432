import { Can } from "config/Can";
import { useSelector } from "react-redux";
const { Space, Button } = require("antd")
const {PlusOutlined,SyncOutlined,QuestionOutlined } = require("@ant-design/icons")

const BaseToolBar = (props) => {
    const auth = useSelector(state => state.authSlice);

    const addNew = () => {
        if (props.addNew) props.addNew();
    }
    const refresh = () => {
        if (props.refresh) props.refresh();
    }
    const help = () => {
        if (props.help) props.help();
    }

    return <div className="toolbar-box">
        <Space>
            <Can I="create" a={props.resourceName} passThrough={auth?.userProfile?.roles?.includes("ROLE_SUPER_ADMIN")}>
                <Button icon={<PlusOutlined/>} onClick={addNew}>Add new</Button>
            </Can>
            <Button style={{color:'#09ad09'}} icon={<SyncOutlined  />} onClick={refresh}>Refresh</Button>
            <Button style={{color:'#1890ff'}} icon={<QuestionOutlined />} onClick={help}>Help</Button>
        </Space>
    </div>
}
export default BaseToolBar;