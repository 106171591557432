const { default: axios } = require("axios");
const { getCookie } = require("utils/common");
const { ACCESSTOKEN } = require("utils/constants");

const defaultHeaders = {
    "Content-Type": "application/json"
}

const baseUrl = process.env.REACT_APP_API_URL;

const defaultRequestTimeout = 30000;

const createAxiosInstance = (baseUrl, timeout, headers) => {
    return axios.create({
        baseURL: baseUrl,
        timeout,
        headers
    });
}

const httpClient = {
    getWithAuthorization: (uri, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const accessToken = getCookie(ACCESSTOKEN);
        const config = {
            headers: {
                Authorization: accessToken,
            },
        }
        return axiosInstance.get(uri, config);
    },
    postWithAuthorization: (uri, params = {}, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const accessToken = getCookie(ACCESSTOKEN);
        const config = {
            headers: {
                Authorization: accessToken,
            },
        }
        return axiosInstance.post(uri, params, config);
    },
    putWithAuthorization: (uri, params = {}, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const accessToken = getCookie(ACCESSTOKEN);
        const config = {
            headers: {
                Authorization: accessToken,
            },
        }
        return axiosInstance.put(uri, params, config);
    },
    deleteWithAuthorization: (uri, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const accessToken = getCookie(ACCESSTOKEN);
        const config = {
            headers: {
                Authorization: accessToken,
            },
        }
        return axiosInstance.delete(uri, config);
    },
    get: (uri, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const config = {}
        return axiosInstance.get(uri, config);
    },
    post: (uri, defaultUrl = baseUrl, timeout = defaultRequestTimeout, headers = defaultHeaders) => {
        const axiosInstance = createAxiosInstance(defaultUrl, timeout, headers);
        const config = {}
        return axiosInstance.get(uri, {}, config);
    },
}

export default httpClient;