import axios from 'axios'
import { getApiUrl } from 'utils/common'
import { getAuthorizationHeader, getDefaultHeader } from 'utils/common'
export function createVote(data) {
    var url = getApiUrl()+'/Projects/Vote_Create/auth/Create';
    const config = getAuthorizationHeader()
    return axios.post(url, data, config)
}
export function createRegisterVote(data) {
    var url = getApiUrl()+'/Projects/Vote_Register/auth/Create';
    const config = getAuthorizationHeader()
    return axios.post(url, data, config)
}
export function getRegisterVote(data) {
    var url = getApiUrl()+'/Projects/Vote_Register/auth/GetList';
    const config = getAuthorizationHeader()
    return axios.post(url, data, config)
}

export function updateMilestone(data) {
    var url = getApiUrl()+'/Projects/Pool_Step03/auth/UpdateMilestone';
    const config = getAuthorizationHeader()
    return axios.put(url, data, config)
}

export function createClaim(data) {
    var url = getApiUrl()+'/Projects/Vote_Claimable/auth/Create';
    const config = getAuthorizationHeader()
    return axios.post(url, data, config)
}
export function getClaim(data) {
    var url = getApiUrl()+'/Projects/Vote_Claimable/auth/GetList';
    const config = getAuthorizationHeader()
    return axios.post(url, data, config)
}
export function getProposalInfo(data){
    var url = getApiUrl()+'/Projects/Vote_Proposal/auth/GetList';
    const config = getAuthorizationHeader();
    return axios.post(url, data,config);
}