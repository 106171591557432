import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Modal, Row, Space, Table } from 'antd';
import { useEffect, useState } from 'react';
import { getPoolInfo } from 'apis/apipool';
import moment from 'moment';
const MilestoneTable = (props) => {
    const [loading, setLoading] = useState(false);
    const [milestones, setMilestones] = useState([]);
    const [pool, setPool] = useState(null);
    const [acceptRowIndex, setAcceptRowIndex] = useState(-1)
    const [type, setType] = useState(-1)
    const loadData = async () => {
        setLoading(true);
        const rs = await getPoolInfo(props.pool.PoolID);
        const arr = rs?.data?.Data?.Step03?.Milestones;
        const pool = rs?.data.Data;
        setPool(pool);
        if (arr)
            setMilestones(arr);
        setLoading(false);
    }
    const converDateTime = (timeStamp) => {
        const date = new moment.unix(timeStamp);
        return date.format("DD/MM/YYYY HH:mm:ss");
    }
    const onConfirmAcceptInvestor = (row, values) => {
        setAcceptRowIndex(-1)
        if (props.createVote)
            props.createVote(row, pool, type, values, loadData)
    }
    const renderAcceptModal = () => {
        if (acceptRowIndex < 0) {
            return null
        }
        const row = milestones[acceptRowIndex]
        return (
            <Modal visible onCancel={() => setAcceptRowIndex(-1)} footer={null}>
                <Form
                    initialValues={{ registerBlock: 100, voteBlock: 100 }}
                    layout="vertical"
                    onFinish={(values) => {
                        onConfirmAcceptInvestor(row, values, acceptRowIndex)
                    }}
                >
                    <Form.Item name="registerBlock" label="Register block" rules={[{ required: true }]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="voteBlock" label="Vote block" rules={[{ required: true }]}>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Row justify="end">
                        <Space size="large">
                            <Button htmlType="submit" type='primary'>Accept</Button>
                            <Button onClick={() => setAcceptRowIndex(-1)} danger>Cancel</Button>
                        </Space>
                    </Row>
                </Form>
            </Modal>
        )
    }
    useEffect(() => {
        loadData();
    }, []);

    const columns = [
        {
            title: 'Milestone Name',
            dataIndex: 'MilestoneName',
            key: 'MilestoneName',
        },
        {
            title: 'Start Time',
            dataIndex: 'StartTime',
            key: 'StartTime',
            render: (val) => converDateTime(val)
        },
        {
            title: 'End Time',
            dataIndex: 'EndTime',
            key: 'EndTime',
            render: (val) => converDateTime(val)
        },
        {
            title: 'Disbursement percent',
            dataIndex: 'DisbursementPer',
            key: 'DisbursementPer',
        },
        {
            title: 'Action',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record, rowIndex) => (
                <Space>
                    {
                        !record.DisburseProposalID ?
                            <Button onClick={() => {
                                setType(0);
                                setAcceptRowIndex(rowIndex);
                            }} icon={<PlusCircleOutlined style={{ color: "white" }} />} type="primary" >Create vote disburse</Button>
                            : <Button onClick={() => {
                                if (props.checkVote)
                                    props.checkVote(0, record.DisburseProposalID, pool)
                            }} type='primary' >Check vote disburse</Button>

                    }
                    {
                        !record.RefundProposalID ?
                            <Button onClick={() => {
                                setType(1);
                                setAcceptRowIndex(rowIndex);
                            }} icon={<PlusCircleOutlined style={{ color: "red" }} />} danger >Create vote refund</Button> :
                            <Button onClick={() => {
                                if (props.checkVote)
                                    props.checkVote(1, record.RefundProposalID, pool)
                            }} danger>Check vote refund</Button>

                    }

                </Space>
            ),
        },
    ];
    return <>
        <Table rowKey="MilestoneName" loading={loading} columns={columns} dataSource={milestones} pagination={false}>
        </Table>
        {renderAcceptModal()}
    </>;
}
export default MilestoneTable;