import { RightCircleTwoTone } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { useSwitchChain } from 'hooks/useSwitchChain';
import { useDispatch, useSelector } from 'react-redux';
import { closeConfirmSwitchChain, networkSelector } from 'redux/reducers/networkSlice';

export const SwitchChainModal = () => {
    const network = useSelector(networkSelector)
    const dispatch = useDispatch();
    const { onSwitch } = useSwitchChain();
    const switchNetwork = async () => {
        const chain = getChainById();
        onSwitch(chain);
    }
    const getChainById = () => {
        let chain = network?.listChain?.find((item) => item.chainID === network.switchChainId);
        if (!chain) chain = { icon: '', chainId: '', chainName: '' }
        return chain;
    }

    const getCurrentChainById = () => {
        let chain = network?.listChain?.find((item) => item.chainID === network.currentChainId);
        if (!chain) chain = { icon: '', chainId: '', chainName: '' }
        return chain;
    }

    const onCancel = () => {
        dispatch(closeConfirmSwitchChain())
    }

    return network.showConfirmSwitchChain ?
        <Modal
            width={500}
            style={{ padding: 0 }}
            bodyStyle={{ background: "#1C1C20 url('/images/bg-wave.png') no-repeat top left" }}
            visible={true}
            footer={null}
            onCancel={null}
            closable={false}
        >
            <div class="modal-header">
                <h5 class="modal-title">{network.switchTitle}</h5>
            </div>
            <div class="modal-body">
                <div class="group_connect">
                    <p class="text-center">{network.switchDescription}</p>
                    <Row justify='space-around' style={{ margin: '20px 0'}}>
                        <Col xs={8} className="switch-chain__chain-logo">
                            {/* <div class="item-convert convert-to"> */}
                                <img src={getCurrentChainById().icon} alt="" />
                                <p>{getCurrentChainById().chainName}</p>
                            {/* </div> */}
                        </Col>
                        <RightCircleTwoTone className='switch-chain__icon' />
                        <Col xs={8} className="switch-chain__chain-logo">
                            {/* <div class="item-convert"> */}
                                <img src={getChainById().icon} alt="" />
                                <p>{getChainById().chainName}</p>
                            {/* </div> */}
                        </Col>
                    </Row>
                </div>
            </div>
            <Row justify='end' className='switch-chain-modal__footer'>
                <Button onClick={onCancel} type="button">
                    Cancel
                </Button>
                <Button type="primary" onClick={switchNetwork}>
                    Confirm
                </Button>
            </Row>
        </Modal> : <></>


}