import { combineReducers } from "redux";
import connectWalletSlice from "./connectWalletSlice";
import projectSlice from "./projectSlice";
import layoutSlice from "./layoutSlice";
import accountSlice from "./accountSlice";
import authSlice from "./authSlice";
import networkSlice from './networkSlice'
let reducers = combineReducers({
  connectWalletSlice: connectWalletSlice,
  projectSlice,
  layoutSlice,
  accountSlice,
  authSlice,
  network:networkSlice
});

export default reducers;
