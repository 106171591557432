import {
  FileDoneOutlined,
  UsergroupAddOutlined,
  FolderOutlined,
  FileOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  SettingOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Popover } from "antd";
import React, { useContext, useState } from "react";
import ConnectWallet from "components/common/connect-wallet/ConnectWallet";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTitle } from "redux/reducers/layoutSlice";
import adminIcon from "assets/img/admin-icon.ico";
import { useEffect } from "react";
import { AbilityBuilder } from "@casl/ability";
import { AbilityContext } from "config/Can";
import { getMyRoleResources } from "apis/apiroleresource";
import { MENU_ITEMS } from "./menuItem";
import { authActions } from "redux/reducers/authSlice";
import { setUserAbilities } from "redux/reducers/authSlice";
const { Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

// const items = [
//   getItem("Document", "document", <FolderOutlined />, [
//     getItem("White paper", "white-paper", <FileOutlined />),
//     getItem("Blog", "blog", <FormOutlined />),
//     getItem("FAQ", "faq", <QuestionCircleOutlined />),
//   ]),
//   getItem("Account", "account", <UserOutlined />),
//   getItem("Pool", "pool", <AppstoreOutlined />, [
//     getItem("Summary pool", "summary", <SnippetsOutlined />),
//     getItem("Create vote", "create-vote", <UsergroupAddOutlined />),
//   ]),
//   getItem("Buidl", "buidl", <ProjectOutlined />, [
//     getItem("Approve project", "approve-project", <FileDoneOutlined />),
//   ]),
//   getItem("Dictionary", "", <ProjectOutlined />, [
//       getItem("Fundraising Stages", "fundraising-stages", <FileDoneOutlined />),
//       getItem("Interested Fields", "interested-fields", <FileDoneOutlined />),
//       getItem("Chains", "chains", <FileDoneOutlined />),
//       getItem("Hash Tags", "hash-tags", <FileDoneOutlined />),
//       getItem("Currency", "currencies", <FileDoneOutlined />),
//       getItem("Venture Capital Type", "venture-capital-types", <FileDoneOutlined />),
//       getItem("Interested Stage", "interested-stages", <FileDoneOutlined />)
//   ]),
//   getItem("Authorizations", "authorization", <SettingOutlined />, [
//     getItem("Role", "roles", <FileDoneOutlined />),
//     getItem("Resource", "resources", <FileDoneOutlined />)
//   ]),
//   getItem("Setting", "setting", <SettingOutlined />),
// ];

const App = () => {
  const navigate = useNavigate();
  const authState = useSelector(state => state.authSlice);
  const ability = useContext(AbilityContext);
  const [collapsed, setCollapsed] = useState(false);

  const menuClick = (sender) => {
    var title =sender?.domEvent?.currentTarget?.textContent;
    if(!title) title = sender.key;
    dispatch(setTitle(title));
    navigate(sender.key);
  };

  const dispatch = useDispatch();
  const title = useSelector((state) => state.layoutSlice.title);
  const openKeys = useSelector((state) => state.layoutSlice.openKeys);
  const [ menuItems, setMenuItems ] = useState([]);

  useEffect(() => {
    if (authState?.userProfile?.roles?.includes("ROLE_SUPER_ADMIN")) {
      const fullMenus = convertToMenuData(MENU_ITEMS);
      setMenuItems(fullMenus);
      return;
    }
    if (ability?.rules && ability.rules.length > 0) {
      const menuItems = removeUnabilityMenu(MENU_ITEMS);
      setMenuItems(menuItems);
    }
  }, [ability.rules, authState?.userProfile]);

  const removeUnabilityMenu = (menu) => {
    return (
        menu.flatMap(({resourceName, subMenus, name, icon}) =>
            ability?.can('view', resourceName)
                ? [getItem(name, resourceName, icon, subMenus && (removeUnabilityMenu(subMenus)))]
                : []
        )
    );
  }

  const convertToMenuData = (menu) => {
    return (
      menu.flatMap(({resourceName, subMenus, name, icon}) => [getItem(name, resourceName, icon, subMenus && (convertToMenuData(subMenus)))])
    );
  }

  const logout = () => {
    dispatch(authActions.logout({}));
  }

  return (
    <div id="components-layout-demo-side">
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="logo">
            {/* {collapsed?<img src={adminIcon} style={{height:40,marginTop:10}} alt='logo' />:<img src={logo} alt='logo' />} */}
            <img src={adminIcon} style={{ height: 40 }} alt="logo" />{" "}
            {collapsed ? "" : <span>Admin system</span>}
          </div>
          <Menu
            onClick={menuClick}
            theme="dark"
            defaultOpenKeys={openKeys}
            // selectedKeys={selectedKeys}
            // defaultSelectedKeys={["approve-project"]}
            mode="inline"
            items={menuItems}
          />
        </Sider>
        <Layout className="site-layout">
          <div className="header-box">
            <h2>{title}</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ConnectWallet />
              <div style={{marginLeft: '10px'}}>
                <Button onClick={logout}>Logout</Button>
              </div>
            </div>
          </div>
          <Content>
            <div className="main-box">
              <Outlet />
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              borderTop: "1px solid #ddd",
              padding: "13px 50px",
            }}
          >
            Admin ©2022 Created by DStater Team
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default App;
