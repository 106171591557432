import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { uploadFile } from 'apis/apistorage';
import React, { useState } from 'react';

const UploadFile = (props) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(props?.imageUrl);

    const beforeUpload = (file) => {
        const isFileTypeValid = props.types.includes(file.type);
        if (!isFileTypeValid) {
            message.error(`You can only upload ${props.types.join(", ")} file!`);
        }
    
        const isFileSizeValid = file.size / 1024 / 1024 < props.maxSize;
        if (!isFileSizeValid) {
            message.error(`Image must smaller than ${props.maxSize} MB!`);
        }
        return false;
    };

    const handleUploadFile = async (file) => {
        try {
            const response = await uploadFile(file);
            setImageUrl(response.data?.UrlFile);
            props.onImageChange(response.data?.UrlFile);
        } catch (error) {

        } finally {
            setLoading(false);
        }
    }

    const onChange = (info) => {
        handleUploadFile(info.file);
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    return (
        <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={onChange}
        >
            {imageUrl ? (
                <img
                    src={imageUrl}
                    alt="avatar"
                    style={{
                        width: '100%',
                    }}
                />
                ) : (uploadButton)}
        </Upload>
    );
};

export default UploadFile;