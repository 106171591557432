/** @format */

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Button, Table } from "antd";
import { filterInternalSmartContract } from "apis/apiInternalSmartContract";
import BaseFilter from "components/common/controls/BaseFilter";
import BaseToolBar from "components/common/tool-bar/BaseToolBar";
import { Can } from "config/Can";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { abilityActions, filterParamTypes, resourceNames } from "utils/constants";

const ContractAddress = () => {
    const navigate = useNavigate();
    const optionName = resourceNames.contractAddresses;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);

    const initialFilterRequest = {
        pageIndex: 0,
        pageSize: 10,
        params: []
    };
    const [ filterRequest, setFilterRequest ] = useState(initialFilterRequest);

    const filterParams = [
        { checked: false, columnName: "name", type: filterParamTypes.TEXT, columnType: 0, title: "Name" },
        { checked: false, columnName: "code", type: filterParamTypes.TEXT, columnType: 0, title: "Code" },
        { checked: false, columnName: "chainID", type: filterParamTypes.TEXT, columnType: 0, title: "Chain ID" },
        { checked: false, columnName: "address", type: filterParamTypes.TEXT, columnType: 0, title: "Address" },
        // { checked: false, columnName: "address", type: filterParamTypes.DATE, columnType: 0, title: "Address" },
    ];

    useEffect(() => {
        filter();
    }, [filterRequest]);

    const filter = async () => {
        setLoading(true);
        const res = await filterInternalSmartContract(filterRequest);
        setTotalRecord(res.TotalRecords);
        setListData(res.data?.Data);
        setLoading(false);
    };

    const handleFilter = (params) => {
        const newFitlerRequest = { ...filterRequest };
        newFitlerRequest.params = params;
        setFilterRequest(newFitlerRequest);
      }

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Code",
            dataIndex: "code",
            key: "code"
        },
        {
            title: "ChainID",
            dataIndex: "chainID",
            key: "chainID",
            render: (render, record) => {
                return (<div>{record?.chain?.chainID}</div>)
            }
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Action",
            width: 140,
            render: (render, record) => (
                <div>
                    <Can I={abilityActions.update} a={resourceNames.contractAddresses}>
                        <Button
                            title="Edit"
                            icon={<EditOutlined />}
                            onClick={() => navigate("/" + optionName + "/" + record.id)}
                            style={{ margin: "0 10px" }}
                            type="link"
                        ></Button>
                    </Can>
                    <Can I={abilityActions.delete} a={resourceNames.contractAddresses}>
                        <Button
                            title="Delete"
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            style={{ margin: "0 10px" }}
                            type="link"
                        ></Button>
                    </Can>
                </div>
            ),
        },
    ];
    const handlePagination = (event) => {
        setCurrentPage(event);
    }

    const refresh = () => {
        setCurrentPage(1);
        filter();
    };

    const addNew = () => {
        navigate(`/${optionName}/add-new`);
    };

    return (
        <>
            <BaseToolBar
                addNew={addNew}
                refresh={refresh}
                resourceName={resourceNames.contractAddresses}
            />
            <div className='box-list'>
                <BaseFilter filterParams={filterParams} onFilter={(params) => handleFilter(params)} />
                <div style={{ flex: 1, borderLeft:'1px solid #ddd' }}>
                    <div className='flex-scroll-wrapper'>
                        <div className='flex-scroll-content'>
                            <Table
                                dataSource={listData}
                                columns={columns}
                                loading={loading}
                                pagination={{
                                    onChange: handlePagination,
                                    pageSize: 10,
                                    total: totalRecord,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContractAddress;
