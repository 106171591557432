import axios from 'axios';
import { getApiUrl, getAuthorizationHeader, getDefaultHeader, getPrefixAdminApiUrl } from 'utils/common';

export function getAllSmartContract() {
    //internal-smart-contracts/pub/filter
    var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/pub/filter`;
    const request = {
        pageIndex: 0,
        pageSize:1000000
    }
    const config = getDefaultHeader();
    return axios.post(url, request, config);
}

export function filterInternalSmartContract(data) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/pub/filter`;
        const config = getAuthorizationHeader();
        return axios.post(url, data, config)
    } catch (error) {

    }
}

export function createInternalSmartContract(request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/auth/create`;
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {
        throw error;
    }
}

export function deleteInternalSmartContract(id) {
    try {
        const url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.delete(url, config);
    } catch (error) {
        throw error;
    }
}

export function updateInternalSmartContract(id, request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.put(url, request, config);
    } catch (error) {

    }
}

export function getInternalSmartContractById(id) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/internal-smart-contracts/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.get(url, config);
    } catch (error) {

    }
}

