import axios from 'axios';
import { getApiUrl, getAuthorizationHeader, getPrefixAdminApiUrl } from 'utils/common';

export function filterCurrencies(data) {
    try {
        var url =  `${getApiUrl()}/${getPrefixAdminApiUrl()}/currencies/auth/filter`;

        const config = getAuthorizationHeader();
        return axios.post(url, data, config)
    } catch (error) {

    }
}

export function createCurrency(request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/currencies/auth/create`;
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {
        throw error;
    }
}

export function deleteCurrency(id) {
    try {
        const url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/currencies/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.delete(url, config);
    } catch (error) {
        throw error;
    }
}

export function updateCurrency(id, request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/currencies/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.put(url, request, config);
    } catch (error) {

    }
}

export function getCurrencyById(id) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/currencies/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.get(url, config);
    } catch (error) {

    }
}