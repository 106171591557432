import axios from 'axios'
import { getApiUrl } from 'utils/common'
import { getDefaultHeader } from 'utils/common'
export function getPools(data) {
    var url = getApiUrl() + '/Projects/Pool_Information/pub/GetList';
    const config = getDefaultHeader()
    return axios.post(url, data, config)
}
export function getPoolInfo(poolId) {
    var url = getApiUrl() + '/Projects/Pool_Information/pub/GetInfo/' + poolId;
    const config = getDefaultHeader()
    return axios.get(url, config)
}

export function createVote(data) {
    var url = getApiUrl() + '/Projects/Pool_Information/pub/GetList';
    const config = getDefaultHeader()
    return axios.post(url, data, config)
}
