/** @format */

import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { login } from "apis/apiauth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/reducers/authSlice";
import { setCookie } from "utils/common";
import { ACCESSTOKEN, REFRESH_TOKEN } from "utils/constants";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    const res = await login(values);
    if (res.Data) {
      dispatch(authActions.authenticated(res.Data.AccessToken));
      navigate("/");
      setCookie(ACCESSTOKEN, res.Data.AccessToken);
      setCookie(REFRESH_TOKEN, res.Data.RefreshToken);

      // Fetch ability from server and update to redux
    } else {
      message.error(res.Message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="wrapper-login">
      <Card
        style={{
          height: "30vh",
          width: '500px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row>
          <Col span={24}>
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Login;
