import { ethers } from "ethers";
const getSmartContract = (contractAddress,abi,signer) => {
    return new ethers.Contract(contractAddress, abi, signer);
}
const getSigner = () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    return provider.getSigner();
}
const approve = (contract,addressWallet,amount)=>{
    return contract.approve(addressWallet, amount);
}
export {getSmartContract,getSigner,approve};