import { Button, Col, message, Modal, Result, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "redux/reducers/authSlice";
/**
 *
 * @param props { requireAccessToken}
 * @returns
 */
export const AuthRequirement = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { requireAccessToken } = props;
  const { authSlice } = useSelector(authSelector);
  const [requireAccessTokenVisible, setRequireAccessTokenVisible] =
    React.useState(false);

  useEffect(() => {
    // const auth = requireAccessToken && !authSlice.accessToken;
    // if (auth != requireAccessTokenVisible) {
    //   navigate("/login");
    //   setRequireAccessTokenVisible(auth);
    // }
  }, [requireAccessToken, authSlice.accessToken]);
};
