import { Button, Space } from "antd";
import {
    FilterOutlined,
    SearchOutlined,
    ClearOutlined
} from '@ant-design/icons';
import TextControlFilter from "./TextControlFilter";
import { useState,useRef } from "react";
import moment from "moment";
import { filterParamTypes } from "utils/constants";
import DateControlFilter from "./DateControlFilter";
import NumberControlFilter from "./NumberControlFilter";
const BaseFilter = (props) => {
    const [arr, setArr] = useState(props?.filterParams || []);
    const refs= useRef([]);
    const clear = () => {
        const newArr = [...arr];
        newArr.map(item => {
            item.checked = false;
            item.time = moment().unix();
        });
        setArr(newArr);
    }
    console.log('arr: ', arr)
    const filter = () => {
        const filters = [];
        refs.current.map(item=>{
            const obj = item.getData();
            if(obj){
                filters.push(obj);
            }
        });
        if(props.onFilter)
            props.onFilter(filters);
    }

    return <div className="flex-scroll-wrapper">
        <div className="filter-header">
            <Space>
                {/* <t-icon style="color: black; font-size: 16px" type="filter" /> */}
                <FilterOutlined style={{ color: 'black', fontSize: 16 }} />
                <span style={{ fontWeight: 500, color: 'black' }}>Filter</span>
            </Space>
        </div>
        <div className="flex-scroll-content">
            {
                arr.map((item,index) => {
                    if (item.type === filterParamTypes.DATE) {
                        return <DateControlFilter ref={(el)=>refs.current[index] = el} columnName={item.columnName} title={item.title} checked={item.checked}/>
                    }

                    if (item.type === filterParamTypes.NUMBER) {
                        
                        return <NumberControlFilter ref={(el)=>refs.current[index] = el} columnName={item.columnName} title={item.title} checked={item.checked}/>
                    }
                    return <>
                        <TextControlFilter ref={(el)=>refs.current[index] = el} columnName={item.columnName} title={item.title} checked={item.checked} />
                    </>
                })
            }
        </div>
        <div className="filter-footer">
            <Space>
                <Button onClick={filter} icon={<SearchOutlined />} >Search</Button>
                <Button onClick={clear} icon={<ClearOutlined />}>Clear</Button>
                {/* <t-button :disabled="!enabled" @click="onFilter" icon="search"
          >Search</t-button
        >
        <t-button
          :disabled="!enabled"
          @click="resetFilter"
          type="danger"
          icon="close-circle"
          >Clear</t-button
        > */}
            </Space>
        </div>
    </div>;
}
export default BaseFilter;