import axios from 'axios';
import { getApiUrl, getAuthorizationHeader } from 'utils/common';

export function getResources() {
    try {
        var url = getApiUrl() + '/admin/resources/auth/get-list';
        const config = getAuthorizationHeader();
        return axios.get(url, config);
    } catch (error) {
        throw error;
    }
}

export function createRoles(request) {
    try {
        var url = getApiUrl() + '/admin/resource/auth/create';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {
        throw error;
    }
}

export function deleteResource(id) {
    try {
        var url = getApiUrl() + `/admin/resources/auth/${id}`;
        const config = getAuthorizationHeader();
        return axios.delete(url, config);
    } catch (error) {
        throw error;
    }
}

export function upsertResourceOperations(request) {
    try {
        var url = getApiUrl() + '/admin/resource-operations/auth/upsert';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {

    }
}

export function upsertResource(request)  {
    try {
        var url = getApiUrl() + '/admin/resources/auth/upsert';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {

    }
}
