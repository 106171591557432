import { ethers } from "ethers";
import {getServerTime,auth} from 'apis/apiauth'
import {getCurrentProvider} from 'utils/common'
export async function refreshToken(){
    var accessToken = null;
    try {
        const provider = getCurrentProvider();
        const addresses = await provider.send("eth_requestAccounts", []);
        const signer =  provider.getSigner();
        var rsDateTime = await getServerTime();
        const timestamp = rsDateTime.data.Timestamp;
        const hashmessage = timestamp+"#"+"abc";
        const signature = await signer.signMessage(hashmessage);
        const rs = await auth({address:addresses[0], message:hashmessage,signature:signature});
        accessToken = rs.data.Data.AccessToken
    } catch (error) {
        accessToken= "";
    }
    return accessToken;
}