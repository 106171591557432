/** @format */

import { Form, Input, message, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import { filterChains } from "apis/apichains";
import { createCurrency, getCurrencyById, updateCurrency } from "apis/apicurrency";
import BaseToolBarDetail from "components/common/tool-bar/BaseToolBarDetail";
import UploadFile from "components/upload-file/UploadFile";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { fileTypes, resourceNames } from "utils/constants";

const ContractAddressDetail = (props) => {
    const optionName = resourceNames.currencies;
    const [form] = Form.useForm();
    const { id } = useParams();
    const [masterData, setMasterData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();
    const [ chainOptions, setChainOptions ] = useState([]);
    const [ iconUrl, setIconUrl ] = useState('');
    const network = useSelector(state => state.network);

    useEffect(() => {
        loadData();
        fetchChains()
    }, [id]);

    const fetchChains = async () => {
        try {
            const response = await filterChains({ pageIndex: 0, pageSize: 200 });
            const chainOptions = response?.data?.Data?.map((item) => (
                {
                    id: item?.id,
                    name: item.chainName,
                    chainID: item?.chainID
                }
            ));
            setChainOptions(chainOptions);
        } catch (error) {

        }
    }

    const onFinish = async (values) => {
        const saveObj = { ...masterData };
        const keys = Object.keys(values);
        keys.map(key => saveObj[key] = values[key]);
        saveObj.icon = iconUrl;

        if (!id || id !== 'add-new') {
            try {
                await updateCurrency(id, saveObj);
                message.success("Update Currency successfully!");
                setTimeout(() => {
                    navigate(`/${optionName}`)
                }, 300);
            } catch (error) {
                message.error(error);
            }
        } else {
            try {
                await createCurrency(saveObj);
                message.success("Create Currency successfully!");
                setTimeout(() => {
                    navigate(`/${optionName}`);
                }, 300);
            } catch (error) {
                message.success(error);
            }
        }
    };

    const loadData = async () => {
        const res = await getCurrencyById(id);
        setMasterData(res.data?.Data);
        setDataLoaded(true);
        setIconUrl(res.data?.Data?.icon || "");
    }

    const save = () => {
        form.submit();
    }

    const cancel = () => {
        navigate(`/${optionName}`)
    }

    return (
        <>
            {
                dataLoaded ?
                    <>
                        <BaseToolBarDetail save={save} cancel={cancel} />
                        <div style={{ padding: '20px 20px 0px 20px' }}>
                            <Form
                                form={form}
                                name="basic"
                                layout="vertical"
                                initialValues={masterData}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: "Please input Name!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Symbol"
                                    name="symbol"
                                    rules={[{ required: true, message: "Please input Symbol!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Chain ID"
                                    name="chainID"
                                    required
                                >
                                    <Select style={{ width: "25%" }}>
                                        {
                                            network?.listChain?.map((option) => (
                                                <Option value={option.id}>{option.chainName} - {option?.chainID}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Contract Address"
                                    name="contractAddress"
                                    rules={[{ required: true, message: "Please input Contract Address!" }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Icon"
                                    name="icon"
                                    required
                                >
                                    <UploadFile
                                        types={[fileTypes.IMAGE_JPEG, fileTypes.IMAGE_PNG, fileTypes.IMAGE_GIF]}
                                        maxSize={2}
                                        onImageChange={(imageUrl) => setIconUrl(imageUrl)}
                                        imageUrl={iconUrl}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="status"
                                    label="Status"
                                >
                                    <Select style={{ width: '25%' }}>
                                        <Option value="ACTIVE">ACTIVE</Option>
                                        <Option value="INACTIVE">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                    : <div><Spin size="large"></Spin></div>
            }
        </>
    );
};

export default ContractAddressDetail;
