import createSagaMiddleware from 'redux-saga';
//reducers
import reducers from '../reducers';
//sagas
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import { CookieStorage } from 'redux-persist-cookie-storage'
import rootSaga from '../sagas';
import Cookies from 'cookies-js';
import { configureStore } from '@reduxjs/toolkit';
import { getPersistConfig } from 'redux-deep-persist';

let sagaMiddleware = createSagaMiddleware();

const whitelist = [
  'authSlice.accessToken',
  'connectWalletSlice',
]
const middleware = [sagaMiddleware];

const rootReducer = (state, action) => {
  // console.log('[ACTION]', action)
  if (action.type == 'ROOT_REDUCER_RESET') {
    // Remove the persisted data
    persistConfig.storage.removeItem(persistConfig.key)
    // Return undefind state to reset the whole tree
    return reducers(undefined, action)
  }
  return reducers(state, action)
}

const persistConfig = getPersistConfig({
  key: 'rootReducer',
  storage: new CookieStorage(Cookies),
  whitelist,
  rootReducer
});

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware),
})
sagaMiddleware.run(rootSaga)
export default store

export const persistor = persistStore(store)
