/** @format */

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ApproveProject from "pages/approve-project/ApproveProject";
import CreateVote from "pages/create-vote/CreateVote";
import Summary from "pages/summary/Summary";
import "antd/dist/antd.min.css";
import "./App.css";
import MainLayout from "pages/Layouts/MainLayout";
import Account from "pages/account/Account";
import PrivateRoute from "components/common/router/PrivateRoute";
import Login from "components/login";
import PageNotFound from "components/page-not-found";
import VentureOption from "components/option-config/VentureCapitalType";
import VentureDetail from "components/option-config/VentureCapitalType/detail";
import InterestedStageOption from "components/option-config/InterestedStage";
import HashtagDetail from "components/option-config/HashTags/detail";
import HashtagOption from "components/option-config/HashTags";
import FundraisingOption from "components/option-config/FundraisingStages";
import FundraisingDetail from "components/option-config/FundraisingStages/detail";
import InterestedFieldOption from "components/option-config/InterestedFields";
import InterestedDetail from "components/option-config/InterestedFields/detail";
import CurrenciesDetail from "components/option-config/Currency/detail";
import CurrencyOption from "components/option-config/Currency";
import InterestedStageDetail from "components/option-config/InterestedStage/detail";
import RolePage from "pages/authorization/role";
import ResourcePage from "pages/authorization/resources";
import { AbilityContext } from "config/Can";
import ability from "config/ability";
import { resourceNames } from "utils/constants";
import Chains from "components/option-config/Chains";
import ChainsDetail from "components/option-config/Chains/detail";
import ContractAddress from "components/option-config/ContractAddress";
import ContractAddressDetail from "components/option-config/ContractAddress/detail";
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from 'redux/stores/index';
import VentureCapitalTypes from "components/option-config/VentureCapitalType";
import VentureCapitalTypeDetail from "components/option-config/VentureCapitalType/detail";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllChain } from "apis/apichains";
import { getAllSmartContract } from "apis/apiInternalSmartContract";
import { updateListChain, updateListContract } from "redux/reducers/networkSlice";
import { Spin } from "antd";
function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getInitDataContract = async () => {
    const chains = await getAllChain()
    const listContract = await getAllSmartContract()
    if (chains.data.Data) {
      dispatch(updateListChain(chains.data.Data))
    }
    if (listContract.data.Data) {
      dispatch(updateListContract(listContract.data.Data))
    }
    setLoading(false);
  }
  useEffect(() => {
    getInitDataContract();
  }, []);
  const comLoading = () => {
    return <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
      <Spin size='large'></Spin>
    </div>
  }
  return (
    loading ? comLoading() : <>
      <PersistGate loading={null} persistor={persistor}>
        <AbilityContext.Provider value={ability}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <MainLayout />
                }
              >
                <Route
                  index
                  path="/"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.approveProject}>
                      <ApproveProject />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/approve-project"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.approveProject}>
                      <ApproveProject />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/summary"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.summaryPool}>
                      <Summary />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/create-vote"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.summaryPool}>
                      <CreateVote />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/account"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.account}>
                      <Account />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/roles"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.roles}>
                      <RolePage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/resources"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.resources}>
                      <ResourcePage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/fundraising-stages"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.fundraisingStages}>
                      <FundraisingOption />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/chains"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.chains}>
                      <Chains />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/chains/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.chains}>
                      <ChainsDetail />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/internal-smart-contracts/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.contractAddresses}>
                      <ContractAddressDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/internal-smart-contracts"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.contractAddresses}>
                      <ContractAddress />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/summary-pool"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.summaryPool}>
                      <Summary />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/venture-capital-types/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.ventureCapitalTypes}>
                      <VentureCapitalTypeDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/venture-capital-types"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.ventureCapitalTypes}>
                      <VentureCapitalTypes />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/hash-tags/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.hashTags}>
                      <HashtagDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/hash-tags"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.hashTags}>
                      <HashtagOption />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/interested-fields/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.interestedFields}>
                      <InterestedDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/interested-fields"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.interestedFields}>
                      <InterestedDetail />
                      <InterestedFieldOption />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/currencies/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.currencies}>
                      <CurrenciesDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/currencies"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.currencies}>
                      <CurrencyOption />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/interested-stages/:id"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.interestedStages}>
                      <InterestedStageDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/interested-stages"
                  element={
                    <PrivateRoute requireAccessToken resource={resourceNames.interestedStages}>
                      <InterestedStageOption />
                    </PrivateRoute>
                  }
                />

                <Route path="/white-paper" element={<PageNotFound />} />
                <Route path="/blog" element={<PageNotFound />} />
                <Route path="/faq" element={<PageNotFound />} />
                <Route path="/setting" element={<PageNotFound />} />
              </Route>
            </Routes>
            <Routes>
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </AbilityContext.Provider>
      </PersistGate>
    </>
  );
}

export default App;
