import { getSigner, getSmartContract } from 'smart-contract/common'
import saleConfig from 'smart-contract/abi/TSSaleFactory.json'
import { getContractByCode } from 'utils/common'
import { SmartContractCode } from 'utils/constants'


const getSaleContract = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    const CONTRACT_SALE_ADDRESS = getContractByCode(SmartContractCode.SALE_CONTRACT, chainId);
    return getSmartContract(CONTRACT_SALE_ADDRESS, saleConfig.abi, getSigner())
}

export async function summarySale(smSaleId) {
    const contract = await getSaleContract();
    const rs = await contract.saleSummary(smSaleId);
    const result = await rs.wait();
    return result;
}

export async function getSaleConfigByIndex(smSaleId) {
    try {
        const contract = await getSaleContract();
        const result = await contract.getSaleConfigByIndex(smSaleId);
        return result;
    } catch (err) {
        console.error(err);
    }
    return null;
}
