import axios from 'axios';
import { getApiUrl, getAuthorizationHeader } from 'utils/common';

export function getRoleResources(params) {
    try {
        var url = getApiUrl() + '/admin/role-resources/auth/filter';
        const config = getAuthorizationHeader();
        return axios.post(url, params, config);
    } catch (error) {
        throw error;
    }
}

export function saveRoleResources(request) {
    try {
        var url = getApiUrl() + '/admin/role-resources/auth/upsert';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {

    }
}

export function getMyRoleResources() {
    try {
        var url = getApiUrl() + '/admin/role-resources/auth/my-role-resources';
        const config = getAuthorizationHeader();
        return axios.get(url, config);
    } catch (error) {

    }
}
