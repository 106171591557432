import { getSigner, getSmartContract } from 'smart-contract/common'
import projectConfig from 'smart-contract/abi/TSProject.json'
import { getContractByCode } from 'utils/common'
import { SmartContractCode } from 'utils/constants'


const getTSProjectContract = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    
    const CONTRACT_TSPROJECT_ADDRESS = getContractByCode(SmartContractCode.TSPROJECT_CONTRACT,chainId);
    return getSmartContract(CONTRACT_TSPROJECT_ADDRESS, projectConfig.abi, getSigner())
}
export async function approveProject(smProjectId) {
    const contract = await getTSProjectContract();
    const rs = await contract.approveProject(smProjectId);
    const result = await rs.wait();
    return result;
}