import { CheckOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Menu, Modal, Popconfirm, Row, Space, Table } from "antd";
import { getResources } from "apis/apiresources";
import { getRoleResources, saveRoleResources } from "apis/apiroleresource";
import { createRoles, deleteRole, getRoles } from "apis/apiroles";
import BaseToolBar from "components/common/tool-bar/BaseToolBar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { resourceNames } from 'utils/constants';
  
  const RolePage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const [ resources, setResources ] = useState([]);
    const [ roleResources, setRoleResources ] = useState([]);
    const [ currentRole, setCurrentRole ] = useState();
    const [ roleOperations, setRoleOperations ] = useState([]);
    const [ isModalOpen, setModalOpen ] = useState(false);
    const [ roleDeletion, setRoleDeletion ] = useState({
        open: false,
        role: null
    });
    const [form] = Form.useForm();

    useEffect(() => {
        fetchRoleResourceData();
        fetchResource();
    }, []);

    const onOpenModal = () => {
        setModalOpen(true);
    }

    const onHideModal = () => {
        setModalOpen(false);
    }

    const handleCreateRole = async (values) => {
        try {
            const request = {
                name: values.name
            };
            await createRoles(request);
            onHideModal();
            const response = await getRoles();
            setRoles(response.data?.Data || []);
            form.resetFields();
        } catch (error) {

        }
    }

    const onDeleteRole = async () => {
        try {
            await deleteRole(roleDeletion?.role);
            closeDeleteRoleModal();
            const response = await getRoles();
            setRoles(response.data?.Data || []);
        } catch (error) {

        }
    }

    const openDeleteRoleModal = (role) => {
        setRoleDeletion({ role, open: true })
    }

    const closeDeleteRoleModal = () => {
        setRoleDeletion({ role: null, open: false })
    }

    const fetchRoleResourceData = async () => {
        try {
            const response = await getRoles();
            setRoles(response.data?.Data || []);
            const firstRole = response.data?.Data?.length > 0 ? response.data?.Data[0] : {}
            setCurrentRole(firstRole);

            const roleResourceRespnse = await getRoleResources();
            setRoleResources(roleResourceRespnse?.data?.Data || []);
            const roleResource = roleResourceRespnse?.data?.Data?.find(item => item?.roleName === firstRole?.name);
            setRoleOperations(roleResource?.operations || []);
        } catch (error) {

        }
    }

    const fetchResource = async () => {
        try {
            const response = await getResources();
            setResources(response?.data?.Data || []);
        } catch(error) {

        }
    }

    const onChangeOperations = (operation) => {
        const checked = roleOperations.find(item => item.id === operation.id);
        let newRoleOperations = [ ...roleOperations ];
        if (checked) {
            newRoleOperations = newRoleOperations.filter(item => item.id != operation.id);
        } else {
            newRoleOperations = [ ...newRoleOperations, operation ];
        }

        setRoleOperations(newRoleOperations);
    }
  
    

    const onChangeMenuItem = (e) => {
        const role = roles.find(item => item?.name === e.key);
        setCurrentRole(role);
        const roleResource = roleResources?.find(item => item.roleName === role?.name);
        setRoleOperations(roleResource?.operations || []);
    }

    const onSave = async (record) => {
        try {
            const currentRoleResource = roleResources.find(item => item.roleName === currentRole.name);
            const request = {
                id: currentRoleResource?.id || null,
                roleName: currentRoleResource?.roleName || currentRole?.name,
                operations: roleOperations
            };
            await saveRoleResources(request);
            // setRoleOperations(response?.data?.Data?.operations);

            const roleResourceRespnse = await getRoleResources();
            setRoleResources(roleResourceRespnse?.data?.Data || []);
        } catch (error) {

        }
    }

    const onCancel = () => {
        const roleResource = roleResources?.find(item => item.roleName === currentRole?.name);
        setRoleOperations(roleResource?.operations || []);
    }

    const renderOperations = (operations) => {
        const currentRoleOperationIds = roleOperations?.map(item => item.id);

        return (
            <Row>
                {
                    operations?.map((operation, index) => {
                        return (
                            <Checkbox
                                value={operation?.id}
                                checked={currentRoleOperationIds?.includes(operation?.id)}
                                onChange={() => onChangeOperations(operation)}
                            >{operation.name}</Checkbox>
                        )
                    })
                }
            </Row>
        )
    }
  
    const columns = [
        {
            title: "Resource name",
            key: "name",
            dataIndex: 'name',
        },
        {
            title: "Operations",
            key: "",
            render: (render, record) => (
                <div>
                    {renderOperations(record.operations)}
                </div>
            ),
        },
        {
            title: "Actions",
            render: (render, record) => (
                <Space>
                    <Popconfirm title="Are you sure want to approve?"
                        onConfirm={() => onSave(record)}
                        okText="Yes"
                        cancelText="No">
                        <Button type="link" icon={<CheckOutlined style={{ color: "green" }} />}>Save</Button>
                    </Popconfirm>
                    <Button
                        type="link"
                        icon={<StopOutlined style={{ color: "red" }} />}
                        onClick={onCancel}
                    >Cancel</Button>
                </Space>
            ),
        }
    ];

    return (
      <>
        <div className='flex-scroll-wrapper'>
            <div className='flex-scroll-content'>
                <BaseToolBar
                    addNew={onOpenModal}
                    resourceName={resourceNames.roles}
                />
                <Row>
                    <Col span={6}>
                        <Menu selectedKeys={currentRole ? [currentRole.name] : []} onSelect={onChangeMenuItem}>
                            {
                                roles?.length > 0 && (
                                    roles.map((item) => <Menu.Item key={item?.name}>
                                        <Row justify='space-between' align="middle">
                                            <span>{item?.name}</span>
                                            <DeleteOutlined style={{ color: "red" }} onClick={() => openDeleteRoleModal(item)} />
                                        </Row>
                                    </Menu.Item>)
                                )
                            }
                        </Menu>
                    </Col>
                    <Col span={18}>
                        <Table
                            dataSource={resources}
                            columns={columns}
                            loading={loading}
                        />
                    </Col>
                </Row>
            </div>
        </div>
        

        <Modal title="Add role" visible={isModalOpen} onCancel={onHideModal} footer={null}>
            <Form
                form={form}
                initialValues={{ registerBlock: 100, voteBlock: 100 }}
                layout="vertical"
                onFinish={(values) => {
                    handleCreateRole(values)
                }}
            >
                <Form.Item
                    name="name"
                    label="Role name"
                    rules={[{ required: true, message: "Please input your resource!" }]}
                >
                    <Input />
                </Form.Item>

                <Row justify="end">
                    <Space size="large">
                        <Button htmlType="submit" type='primary'>Save</Button>
                        <Button onClick={onHideModal} danger>Cancel</Button>
                    </Space>
                </Row>
            </Form>
        </Modal>

        <Modal title="Delete role" visible={roleDeletion.open} onCancel={closeDeleteRoleModal} footer={null}>
            <p>Are you sure to delete this role?</p>
            
            <Row justify="end">
                <Space size="large">
                    <Button onClick={onDeleteRole} danger>Delete</Button>
                    <Button onClick={closeDeleteRoleModal}>Cancel</Button>
                </Space>
            </Row>
        </Modal>
      </>
    );
  };
  export default RolePage;
  