/** @format */

import { Checkbox, Col, Form, Input, message, Row, Select, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import API from "apis/apiconfig";
import { useParams, useNavigate } from 'react-router-dom';
import BaseToolBarDetail from "components/common/tool-bar/BaseToolBarDetail";
import { fileTypes, resourceNames, statusCommon } from "utils/constants";
import { Option } from "antd/lib/mentions";
import { createChain, getChainById, updateChain } from "apis/apichains";
import UploadFile from "components/upload-file/UploadFile";
const { PlusOutlined, MinusCircleOutlined, QuestionOutlined } = require("@ant-design/icons");
const { Space, Button } = require("antd")

const ChainsDetail = (props) => {
    const optionName = resourceNames.chains;
    const [form] = Form.useForm();
    const { id } = useParams();
    const [masterData, setMasterData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const navigate = useNavigate();
    const [ iconUrl, setIconUrl ] = useState();

    const onFinish = async (values) => {
        const saveObj = { ...masterData };
        const keys = Object.keys(values);
        keys.map(key => saveObj[key] = values[key]);
        saveObj.icon = iconUrl;

        if (!id || id !== 'add-new') {
            try {
                await updateChain(masterData?.id, saveObj);
                message.success("Update chain successfully!");
                setTimeout(() => {
                    navigate(`/${optionName}`)
                }, 300);
            } catch (error) {
                message.error(error);
            }
        } else {
            try {
                await createChain(saveObj);
                message.success("Create chain successfully!");
                setTimeout(() => {
                    navigate(`/${optionName}`);
                }, 300);
            } catch (error) {
                message.success(error);
            }
        }
    };

    const loadData = async () => {
        try {
            const res = await getChainById(id);
            setMasterData(res.data?.Data);
            setDataLoaded(true);
            setIconUrl(res.data?.Data.icon);
        } catch (error) {
        }
    }
    const save = () => {
        form.submit();
    }
    const cancel = () => {
        navigate(`/${optionName}`)
    }
    useEffect(() => {
        loadData();
    }, [id]);

    return (
        <>
            {
                dataLoaded ?
                    <>
                        <BaseToolBarDetail save={save} cancel={cancel} />
                        <div style={{ padding: '20px 20px 0px 20px' }}>
                            <Form
                                form={form}
                                name="basic"
                                layout="vertical"
                                initialValues={masterData}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Chain name"
                                    name="chainName"
                                    rules={[{ required: true, message: "Please input chain name!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Chain ID"
                                    name="chainID"
                                    rules={[{ required: true, message: "Please input ChainID!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="EVM"
                                    name="vm"
                                    // rules={[{ required: true, message: "Please input ChainID!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Icon"
                                    name="icon"
                                    // rules={[{ required: true, message: "Please input ChainID!" }]}
                                >
                                    <UploadFile
                                        types={[fileTypes.IMAGE_JPEG, fileTypes.IMAGE_PNG, fileTypes.IMAGE_GIF]}
                                        maxSize={2}
                                        onImageChange={(imageUrl) => setIconUrl(imageUrl)}
                                        imageUrl={iconUrl}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Native currency"
                                    name="nativeCurrency"
                                >
                                    <Input.Group compact>
                                        <Form.Item
                                            name={['nativeCurrency', 'name']}
                                            noStyle
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Name is required',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Name" style={{ width: '50%' }} />
                                        </Form.Item>
                                        <Form.Item
                                            name={['nativeCurrency', 'symbol']}
                                            noStyle
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Symbol is required',
                                            },
                                            ]}
                                        >
                                            <Input placeholder="Symbol" style={{ width: '25%' }} />
                                        </Form.Item>
                                        <Form.Item
                                            name={['nativeCurrency', 'decimals']}
                                            noStyle
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Decimal is required',
                                            },
                                            ]}
                                        >
                                            <Input placeholder="Decimal" style={{ width: '25%' }} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                                <label>RPC Urls</label>
                                <Form.List
                                    name="rpcUrls"
                                    initialValue={masterData?.rpcUrls}
                                    rules={[
                                        {
                                          validator: async (_, names) => {
                                            console.log(names)
                                            if (!names || names.length == 0) {
                                              return Promise.reject(new Error("Please input RPC Urls!"));
                                            }
                                          },
                                        },
                                    ]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            <Row>
                                                {
                                                    fields.map((field) => (
                                                        <>
                                                                <Col
                                                                    style={{ marginBottom: 8 }}
                                                                    align="baseline"
                                                                    span={24}
                                                                >
                                                                    <Row justify="start" align="middle">
                                                                        <Col span={12}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                key={field.key}
                                                                                rules={[{ required: true, message: "Please input RPC Url!" }]}
                                                                                style={{ marginBottom: '12px' }}
                                                                            >
                                                                                <Input />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={4} style={{ marginLeft: 8, marginBottom: 12 }}>
                                                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                        </>
                                                    ))
                                                }
                                            </Row>
                                            <Form.ErrorList errors={errors} />
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    // style={{ width: '60%' }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add RPC Url
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                                <>
                                    <label>Block Explorer Urls</label>
                                    <Form.List
                                        name="blockExplorerUrls"
                                        initialValue={masterData?.blockExplorerUrls}
                                        rules={[
                                            {
                                              validator: async (_, names) => {
                                                if (!names || names.length == 0) {
                                                  return Promise.reject(new Error("Please input Block Explorer Urls!"));
                                                }
                                              },
                                            },
                                        ]}
                                    >
                                        {(fields, { add, remove }, { errors }) => (
                                            <>
                                                <Row>
                                                    {
                                                        fields.map((field) => (
                                                            <>
                                                                    <Col
                                                                        style={{ marginBottom: 8 }}
                                                                        align="baseline"
                                                                        span={24}
                                                                    >
                                                                        <Row justify="start" align="middle">
                                                                            <Col span={12}>
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    key={field.key}
                                                                                    rules={[{ required: true, message: "Please input Block Explorer Url!" }]}
                                                                                    style={{ marginBottom: '12px' }}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col span={4} style={{ marginLeft: 8, marginBottom: 12 }}>
                                                                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                            </>
                                                        ))
                                                    }
                                                </Row>
                                                <Form.ErrorList errors={errors} />
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        // style={{ width: '60%' }}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        Add Block Explorer Url
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </>
                                
                                <Form.Item
                                    name="status"
                                    label="Status"
                                >
                                    <Select style={{ width: '25%' }}>
                                        <Option value="ACTIVE">ACTIVE</Option>
                                        <Option value="INACTIVE">INACTIVE</Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                    : <div><Spin size="large"></Spin></div>
            }
        </>
    );
};

export default ChainsDetail;
