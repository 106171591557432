import { Checkbox, Input, Select } from "antd";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
const { Option } = Select;

const TextControlFilter = forwardRef((props, ref) => {
    const [checked, setChecked] = useState(props.checked)
    const [operation, setOperation] = useState(props.operation)
    const [value, setValue] = useState(props.value)

    const checkChange = (evt) => {
        setChecked(evt.target.checked);
    }
    useImperativeHandle(ref, () => ({
        getData() {
            if (checked) {
                return { operation, value, key: props.columnName, type: "text" }
            }
            return null
        },
    }))
    useEffect(() => {
        setChecked(false);
        setOperation(null);
        setValue(null);
    }, [props.time]);
    return <div className="filter-control">
        <Checkbox checked={checked} onChange={checkChange} >{props.title}</Checkbox>
        {
            checked ? <div className="f-controls">
                <Select value={operation} onChange={(val) => setOperation(val)} style={{ width: 60 }}>
                    <Option title="Contains" key='contains'>{"[...]"}</Option>
                    <Option title="Equals" key='equals'>{"="}</Option>
                    <Option title="Start with" key='startwith'>{"[..."}</Option>
                    <Option title="End with" key='endwith'>{"...]"}</Option>
                </Select>
                <Input value={value} onChange={(evt) =>{
                    setValue(evt.currentTarget.value)
                } } style={{ flex: 1, marginLeft: 10 }} />
            </div> : null
        }
    </div>
})
export default TextControlFilter;
