import axios from 'axios';
import { getApiUrl, getAuthorizationHeader, getDefaultHeader, getPrefixAdminApiUrl } from 'utils/common';

export function getAllChain() {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/get-list`;

        const config = getDefaultHeader();
        return axios.get(url,config)
    } catch (error) {

    }
}


export function filterChains(data) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/filter`;

        const config = getAuthorizationHeader();
        return axios.post(url, data, config)
    } catch (error) {

    }
}

export function createChain(request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/create`;
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {
        throw error;
    }
}

export function deleteChain(id) {
    try {
        const url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/${id}`;
        const config = getAuthorizationHeader();
        return axios.delete(url, config);
    } catch (error) {
        throw error;
    }
}

export function updateChain(id, request) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/${id}`;
        const config = getAuthorizationHeader();
        return axios.put(url, request, config);
    } catch (error) {

    }
}

export function getChainById(id) {
    try {
        var url = `${getApiUrl()}/${getPrefixAdminApiUrl()}/chains/pub/${id}`;
        const config = getAuthorizationHeader();
        return axios.get(url, config);
    } catch (error) {

    }
}