import { useDispatch } from 'react-redux'
import { closeConfirmSwitchChain } from 'redux/reducers/networkSlice'
import { requestSwitchNetwork } from 'utils/common'
/**
 * Call api to check user register on current chain when accessToken becomes available or updated
 * @returns
 */
 export const useSwitchChain = () => {
    const dispatch = useDispatch()
    const onSwitch = async (chain) => {
      try {
        await requestSwitchNetwork(chain);
        dispatch(closeConfirmSwitchChain());
      } catch (err) {
        console.log(err);
      }
    }
    return { onSwitch }
  }