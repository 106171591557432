export const ACCESSTOKEN = 'ACCESSTOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
const SmartContractCode = {
  SALE_CONTRACT: 'SALE_CONTRACT',
  UTILS_CONTRACT: 'UTILS_CONTRACT',
  TSPROJECT_CONTRACT: 'TSPROJECT_CONTRACT',
}
const controlType = {
  input: 0,
  inputNumber: 1,
  currency: 2,
  percent: 3,
  select: 4,
  checkbox: 5,
  datepicker: 6,
}
const ProposalState = {
  Pending: 0,
  Active: 1,
  Canceled: 2,
  Defeated: 3,
  Succeeded: 4,
  Queued: 5,
  Expired: 6,
  Executed: 7,
}

const ClaimType = {
  Disbursement: 1,
  Refund: 2,
  SummaryFail: 3,
  LeftOver: 4,
}


const valueType = {
  string: 0,
  int: 1,
  decimal: 2,
  boolean: 3,
  datetime: 4,
}

const statusProject = {
  DRAFF: 0,
  CREATED: 1,
  ACTIVE: 2,
}

const resourceNames = {
  document: 'document',
  whitePaper: "white-paper",
  blog: "blog",
  faq: "faq",
  account: "account",
  pool: "pool",
  summaryPool: "summary-pool",
  createVote: "create-vote",
  buidl: "buidl",
  approveProject: "approve-project",
  dictionary: "dictionary",
  fundraisingStages: "fundraising-stages",
  interestedFields: "interested-fields",
  chains: "chains",
  hashTags: "hash-tags",
  currencies: "currencies",
  ventureCapitalTypes: "venture-capital-types",
  interestedStages: "interested-stages",
  authorizations: "authorizations",
  roles: "roles",
  resources: "resources",
  setting: "setting",
  contractAddresses: "internal-smart-contracts"
}

const abilityActions = {
  view: "view",
  create: "create",
  update: "update",
  delete: "delete"
}

const filterParamOperations = {
  GREATER_THAN: "gt",
  GREATER_THAN_EQUAL: "gte",
  LESS_THAN: "lt",
  LESS_THAN_EQUAL: "lte",
  BETWEEN: "between",
  EQUALS: "equals",
  START_WITH: "startwith",
  END_WITH: "endwith",
  FROM: "from",
  TO: "to",
  CONTAINS: "contains",
}

const filterParamTypes = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
}

const statusCommon = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

const fileTypes = {
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_PNG: 'image/png',
  IMAGE_GIF: 'image/gif',
}

const interalSmartContractCodes = {
  saleContract: {
    name: "Sale contract",
    code: "SALE_CONTRACT"
  },
  utilsContract: {
    name: "Utils contract",
    code: "UTILS_CONTRACT"
  },
  ownerContract: {
    name: "Onwer contract",
    code: "OWNER_CONTRACT"
  },
  tsProjectContract: {
    name: "TSProject contract",
    code: "TSPROJECT_CONTRACT"
  },
  vestingContract: {
    name: "Vesting contract",
    code: "VESTING_CONTRACT"
  },
  daoContract: {
    name: "dao contract",
    code: "DAO_CONTRACT"
  },
}

export { 
  controlType, 
  valueType, 
  statusProject,
  ProposalState,
  ClaimType,
  resourceNames,
  abilityActions,
  filterParamOperations,
  filterParamTypes,
  statusCommon,
  fileTypes,
  interalSmartContractCodes,
  SmartContractCode
};
