import axios from 'axios';
import { getApiUrl, getAuthorizationHeader } from 'utils/common';

export function upsertResourceOperation(request)  {
    try {
        var url = getApiUrl() + '/admin/resource-operations/auth/upsert';
        const config = getAuthorizationHeader();
        return axios.post(url, request, config);
    } catch (error) {

    }
}
