export const ConnectWallet = {
    Metamask: "ConnectWallet_Metamask",
    WalletConnect: "ConnectWallet_WalletConnect",
    Connected:"ConnectWallet_Connected",
    WalletDisconnect: "WalletDisconnect"
}
export const Pool={
    LoadData:"Pool_LoadData",
    LoadInfo:"Pool_LoadInfo",
    LoadDictionary:"Pool_LoadDictionary",
    SaveStep1:"Pool_SaveStep1",
    SaveStep2:"Pool_SaveStep2",
    SaveStep3:"Pool_SaveStep3"
}

export const Auth = {
    LOGOUT: "LOGOUT"
}