/** @format */

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Button, Popconfirm, Table } from "antd";
import { deleteChain, filterChains } from "apis/apichains";
import API from "apis/apiconfig";
import BaseToolBar from "components/common/tool-bar/BaseToolBar";
import { Can } from "config/Can";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { abilityActions, resourceNames } from "utils/constants";

const Chains = () => {
  const navigate = useNavigate();
  const resource = resourceNames.chains;
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const initalFilterParams = {
    pageIndex: 0,
    pageSize: 10
  };
  const [ filterParams, setFilterParams ] = useState(initalFilterParams);

  const fetchChains = async () => {
    setLoading(true);
    const res = await filterChains(filterParams);
    setTotalRecord(res.data?.TotalRecords);
    setListData(res.data?.Data);
    setLoading(false);
  };

  useEffect(() => {
    fetchChains();
  }, [filterParams.pageIndex]);

  const columns = [
    {
      title: "Chain name",
      dataIndex: "chainName",
      key: "chainName",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (render, record) => {
        return <div>{record?.nativeCurrency?.symbol}</div>
      }
    },
    {
      title: "ChainID",
      dataIndex: "chainID",
      key: "chainID",
      render: (render, record) => {
        return <div>{record?.chainID}</div>
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      width: 140,
      render: (render, record) => (
        <div>
          <Can I={abilityActions.update} a={resourceNames.chains}>
            <Button
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => navigate(`/chains/${record.id}`)}
              style={{ margin: "0 10px" }}
              type="link"
            ></Button>
          </Can>
          <Can I={abilityActions.delete} a={resourceNames.chains}>
            <Popconfirm title="Are you sure want to delete?"
              onConfirm={() => handleDeleteChain(record)}
              okText="Yes"
              cancelText="No">
              <Button type="link" icon={<DeleteOutlined style={{ color: "red" }} />} />
            </Popconfirm>
          </Can>
        </div>
      ),
    },
  ];
  const handlePagination = (newPage) => {
    setFilterParams({ ...filterParams, pageIndex: newPage - 1})
  };

  const refresh = () => {
    setFilterParams(initalFilterParams);
  };

  const addNew = () => {
    navigate(`/chains/add-new`);
  };

  const handleDeleteChain = async (chain) => {
    try {
      await deleteChain(chain.id);
      setFilterParams({ filterParams, pageIndex: 0 })
    } catch (error) {

    }
  }

  return (
    <>
      <BaseToolBar
        addNew={addNew}
        refresh={refresh}
        resourceName={resourceNames.chains}
      />
      <Table
        dataSource={listData}
        columns={columns}
        loading={loading}
        pagination={{
          onChange: handlePagination,
          pageSize: filterParams.pageSize,
          total: totalRecord,
          current: filterParams.pageIndex + 1,
        }}
      />
    </>
  );
};
export default Chains;
