import { message, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { getPools } from 'apis/apipool'
import moment from 'moment';
import MilestoneTable from './MilestoneTable';
import { createProposalId, getStatus, getRemainingInfo, checkVote, excutePropose } from 'smart-contract/governorContract'
import { getSaleConfigByIndex } from 'smart-contract/saleContract'
import { createVote, updateMilestone, createRegisterVote, createClaim, getRegisterVote, getClaim } from 'apis/apivote';
import { getOwnerAddress } from 'utils/common';
import { ProposalState, ClaimType } from 'utils/constants'
import { getDisburmentInfo } from 'smart-contract/utilsContract';
import { useSelector,useDispatch } from 'react-redux';
import { updateSwitchChainId } from 'redux/reducers/networkSlice'
const { Column } = Table;
const CreateVote = () => {
    const address = useSelector(state => state.connectWalletSlice.address);
    const [pools, setPools] = useState([]);
    const [loading, setLoading] = useState(false);
    const [busy, setBusy] = useState(false);
    const dispatch = useDispatch();
    const loadData = async () => {
        setLoading(true)
        var rs = await getPools({ pageIndex: 1, pageSize: 1000 });
        var arr = rs?.data?.Data;
        if (!arr)
            arr = [];
        else
            arr = arr.filter(x => x.SmartID != null);
        setLoading(false);
        setPools(arr);
    }
    const converDateTime = (timeStamp) => {
        const date = new moment.unix(timeStamp);
        return date.format("DD/MM/YYYY HH:mm:ss");
    }
    const doCheckVote = async (type, proposalID, pool) => {
        const chainId = pool.Step01.Chain;
        if (!chainId) {
            message.error("Can not identity chain of buidl");
            return;
        }
        const validChain = await checkChainBeforeSubmit(chainId);
        if (!validChain) {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch(updateSwitchChainId({
                chainId: chainId,
                currentChainId: currentChainId,
                title: 'Please switch chain of buidl',
                description: 'You need to switch chain to continue'
            }))
            return;
        }
        const pool_Address = pool?.Step01?.Pool_Address;
        const governorAddress = pool_Address.AddressDao;
        const statusVote = await getStatus(governorAddress, proposalID);
        switch (statusVote) {
            case ProposalState.Succeeded:
                const data = {
                    poolID: pool.PoolID,
                    status: (type === 0 ? ClaimType.Disbursement : ClaimType.Refund),
                    proposalID: proposalID
                }
                const rsClaim = await getClaim(data);
                const count = rsClaim?.data?.TotalRecords;
                if (!count || count == 0) {
                    setBusy(true)
                    const checkvote = await checkVote(governorAddress, proposalID)
                    if (checkvote) {
                        //nếu là vote refund thì tự excute rồi mới create claimable
                        var success = false;
                        if (type === 1) {
                            success = await excute(pool, proposalID);
                        }
                        if (success || type == 0) {
                            const rs = await createClaim(data);
                            message.info("Result of vote is success. The claim have been created")
                        }
                    }
                    setBusy(false)
                }
                else
                    message.info("The claim is created")
                break;
            case ProposalState.Executed:
                message.info("This vote is Executed")
                break;
            case ProposalState.Defeated:
                message.info("This vote is Defeated")
                break;
            default:
                message.warning("This vote not end.")
                break;
        }
    }
    const excute = async (pool, proposalID) => {
        try {
            const pool_Address = pool?.Step01?.Pool_Address;
            if (pool_Address == null) {
                message.warning("Pool has no vesting contract");
                return false;
            }
            const rsSmart = await excutePropose(pool_Address.AddressDao, proposalID);
            if (rsSmart) {
                await rsSmart.wait();
                return true;
            }
        } catch (err) {
            console.log(err);
        }
        return false;
    }

    const checkChainBeforeSubmit = async (chainId) => {
        var result = false;
        const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (currentChainId && chainId) {
            return (currentChainId === chainId)
        }
        return result;
    }

    const doCreateVote = async (milestone, pool, type, config, reLoadMileStone) => {
        const chainId = pool.Step01.Chain;
        if (!chainId) {
            message.error("Can not identity chain of buidl");
            return;
        }
        const validChain = await checkChainBeforeSubmit(chainId);
        if (!validChain) {
            const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
            dispatch(updateSwitchChainId({
                chainId: chainId,
                currentChainId: currentChainId,
                title: 'Please switch chain of buidl',
                description: 'You need to switch chain to continue'
            }))
            return;
        }
        var ownerAddress = address;
        if (!ownerAddress)
            ownerAddress = getOwnerAddress();
        const pool_Address = pool?.Step01?.Pool_Address;
        if (pool_Address == null) {
            message.warning("Pool has no vesting contract");
            return;
        }
        setBusy(true);
        const governorAddress = pool_Address.AddressDao;
        const tokenBuyAddress = pool.Step01.CurrencyTokenAddress;
        //địa chỉ nhận tiền
        //nếu là disburse (type=0) thì là địa chỉ ví của startup
        //nếu là refund (type=1) thì là địa chỉ vesting cho vc claim xèng về
        const receiveddress = type == 0 ? pool.Step01.CUSER : pool_Address.AddressVesting;

        //số tiền còn lại trên smartcontract
        const disburmentInfo = await getDisburmentInfo(pool.SmartID);
        if (disburmentInfo == null) {
            message.warning("Cant not get Disburment information.")
            return;
        }

        var amount = 0;
        var percentRemaining = 0;
        var amountRemaining = 0;

        //số tiền giải ngân
        if (type === 0) {
            const remainingUSD = disburmentInfo.totalRaise;
            amount = (milestone.DisbursementPer / 100) * remainingUSD;
            const remainingInfo = await getRemainingInfo(governorAddress);
            if (remainingInfo === null) {
                message.warning("Can not get Remaining information");
                return;
            }
            percentRemaining = remainingInfo[0];
            amountRemaining = remainingInfo[1];
            amount = (amount + amountRemaining)
        }
        else {
            amount = (disburmentInfo.totalRaise - disburmentInfo.totalDisburment);
        }
        if (amount === 0) {
            message.warning("amount is zero. Cant not create vote");
            return;
        }

        const saleConfig = await getSaleConfigByIndex(pool.SmartID);
        if (saleConfig === null) {
            message.warning("Can not get sale config");
            return;
        }
        //trên smartcontract đang cấu hình là 1.000.000 và số % này đã chia cho 100
        const feeDisbuserment = saleConfig.feeDisbursement / 1000000;
        const feeRefund = saleConfig.feeRefund / 1000000;
        //số tiền phí
        const rateFee = (type === 1) ? feeRefund : feeDisbuserment;
        const isRefund = (type === 1);
        const fee = (amount * rateFee);

        if (amount == 0) {
            message.warning("Cant not create vote with amount 0");
            return;
        }
        //số tiền giải ngân hoặc refund thực nhận
        var totalAmount = amount - fee;
        var proposeId;
        try {
            const totalPercent = (milestone.DisbursementPer + percentRemaining);
            proposeId = await createProposalId(governorAddress, receiveddress, tokenBuyAddress, totalAmount, (milestone.ID+type), fee, ownerAddress, isRefund, totalPercent, config);
        } catch (err) {
            console.log(err);
        }
        if (proposeId) {

            //tạo register vote
            const dataRegister = {
                poolID: pool.PoolID
            }
            const rsDataRegister = await getRegisterVote(dataRegister);
            const countRegister = rsDataRegister?.data?.TotalRecords;
            //mỗi 1 pool chỉ có 1 register vote
            if (!countRegister || countRegister == 0)
                await createRegisterVote(dataRegister);
            //tạo vote
            const data = {
                poolID: pool.PoolID,
                projectID: pool.Step01.ProjectID,
                milestoneID: milestone.ID,
                voteType: type,
                amountDisbursed: amount,
                proposalID: proposeId
            }
            const rs = await createVote(data);

            var dataUpdateMilestone = null;
            //nếu là vote giải ngân
            if (type == 0) {
                dataUpdateMilestone = {
                    poolID: pool.PoolID,
                    milestoneInfo: {
                        id: milestone.ID,
                        disburseProposalID: proposeId
                    }
                };
            }
            else {
                dataUpdateMilestone = {
                    poolID: pool.PoolID,
                    milestoneInfo: {
                        id: milestone.ID,
                        refundProposalID: proposeId
                    }
                };
            }
            const rsUpdateMilestone = await updateMilestone(dataUpdateMilestone);
            reLoadMileStone();
        }
        else {
            message.warning("Can not create ProposalId");
        }
        setBusy(false);
    }
    useEffect(() => {
        loadData();
    }, []);

    return <>
        <Spin spinning={busy}>
            <Table dataSource={pools} rowKey="PoolID" loading={loading} expandable={
                {
                    expandRowByClick: true,
                    expandedRowRender: (evt) => <MilestoneTable pool={evt} createVote={doCreateVote} checkVote={doCheckVote} />
                }
            } >
                <Column width={100} title="Image" dataIndex="Avatar" key="Avatar" render={(image) => <div style={{ width: 80, height: 60, overflow: "hidden" }}><img style={{ height: 60 }} alt='project image' src={image} /></div>} />
                <Column title="Pool Name" dataIndex="NameInvestment" key="NameInvestment" />
                <Column width={200} title="Start time" dataIndex="StartTime" key="StartTime" render={(val) => converDateTime(val)} />
                <Column width={200} title="End time" dataIndex="EndTime" key="EndTime" render={(val) => converDateTime(val)} />
            </Table>
        </Spin>
    </>
}
export default CreateVote