import { createSlice } from '@reduxjs/toolkit';
export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    title:'Approve project',
    selectedKeys:['approve-project'],
    openKeys:['buidl']
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setSelectedKeys:(state, action) => {
      state.selectedKeys = action.payload;
    },
    setOpenKeys:(state, action) => {
      state.openKeys = action.payload;
    },
  },
});

export const { setTitle,setSelectedKeys,setOpenKeys } = layoutSlice.actions;

export const title = (state) => state.title; 
export const selectedKeys = (state) => state.selectedKeys; 
export const openKeys = (state) => state.openKeys;

export default layoutSlice.reducer;