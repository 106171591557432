import { Checkbox, InputNumber, Select } from "antd";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { filterParamOperations } from "utils/constants";
const { Option } = Select;
const NumberControlFilter = forwardRef((props, ref) => {
    const [checked, setChecked] = useState(props.checked)
    const [operation, setOperation] = useState(props.operation)
    const [value, setValue] = useState(props.value)
    const checkChange = (evt) => {
        setChecked(evt.target.checked);
    }
    useImperativeHandle(ref, () => ({
        getData() {
            if (checked) {
                return { operation, value }
            }
            return null
        },
    }))
    useEffect(() => {
        setChecked(false);
        setOperation(null);
        setValue(null);
    }, [props.time]);

    const handleChangeOperation = (value) => {
        console.log(value);
    }
    return <div className="filter-control">
        <Checkbox checked={checked} onChange={checkChange} >{props.title}</Checkbox>
        {
            checked ? <div className="f-controls">
                <Select value={operation} onChange={(val) => handleChangeOperation(val)} style={{ width: 60 }}>
                    <Option title="Equals" key={filterParamOperations.EQUALS}>{"="}</Option>
                    <Option title="Great than" key={filterParamOperations.GREATER_THAN}>{">"}</Option>
                    <Option title="Great than or equals" key={filterParamOperations.GREATER_THAN_EQUAL}>{">="}</Option>
                    <Option title="Less than" key={filterParamOperations.LESS_THAN}>{"<"}</Option>
                    <Option title="Less than or equals" key={filterParamOperations.LESS_THAN_EQUAL}>{"<="}</Option>
                </Select>
                <InputNumber value={value} onChange={(val) =>{
                    setValue(val)
                } } style={{ flex: 1, marginLeft: 10 }} />
            </div> : null
        }
    </div>
})
export default NumberControlFilter;