import axios from "axios";
import { getApiUrl } from "utils/common";
export function auth(data) {
  var url = getApiUrl() + "/identity/TokenAPI/pub/Authentication";
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.post(url, data, config);
}

export function getServerTime() {
  var config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  var url = getApiUrl() + "/identity/TokenAPI/pub/DateTime";
  return axios.get(url, config);
}

const defaultHeaders = {
  "Content-Type": "application/json",
};

export const identityApi = axios.create({
  baseURL: getApiUrl() + "/identity",
  timeout: 30000,
});

// param: {address, message, signature}
export const identityAuthentication = async (authToken, param) => {
  return identityApi
    .post("/TokenAPI/pub/Authentication", param, {
      headers: {
        Authorization: authToken,
        ...defaultHeaders,
      },
    })
    .then((res) => res.data);
};

export const login = async (param) => {
  return identityApi
    .post("/TokenAPI/pub/adds/authentication", param, {
      headers: {
        ...defaultHeaders,
      },
    })
    .then((res) => res.data);
};
