import { createSlice } from '@reduxjs/toolkit';

export const accountSlice = createSlice({
  name: "accounts",
  initialState: {
    accounts: [],
    loading: true,
  },
  reducers: {
    setData: (state, action) => {
      state.accounts = action.payload;
    },
    setLoading:(state,action)=>{
        state.loading = action.payload
    }
  },
});

export const { setData, setLoading } = accountSlice.actions;

export const accounts = (state) => state.accounts;
export const loading = (state) => state.loading;

export default accountSlice.reducer;
