import { AbilityBuilder } from "@casl/ability";
import { getMyRoleResources } from "apis/apiroleresource";
import { AuthRequirement } from "components/login/AuthRequirement";
import PageNotFound from "components/page-not-found";
import { AbilityContext } from "config/Can";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/common";
import { ACCESSTOKEN } from "utils/constants";
import { authActions } from "redux/reducers/authSlice";

/**
 *
 * @param {*} props { requireAccessToken }
 * @returns
 */


 function updateAbility(ability, operations) {
    const { can, rules } = new AbilityBuilder();
  
    for (let i = 0; i < operations.length; i += 1) {
      if (operations[i].resourceName) {
          can(operations[i].action, operations[i].resourceName);
      }
    }

    ability.update(rules);
}

const PrivateRoute = (props) => {
    const { children, resource, requireAccessToken, ...restProps } = props;
    const [ accessable, setAccessable ] = useState(false);
    const authState = useSelector(state => state.authSlice);
    const ability = useContext(AbilityContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!authState.userAbilities || authState.userAbilities.length === 0) {
            updateUserAbilities();
        }
    }, []);

    useEffect(() => {
        if (authState?.userProfile?.roles?.includes("ROLE_SUPER_ADMIN")) {
            setAccessable(true);
            return;
        }
        if (requireAccessToken) {
            if (!getCookie(ACCESSTOKEN)) {
                navigate("/login");
                return;
            }
            if (ability.can('view', resource)) {
                setAccessable(true);
            } else {
                setAccessable(false);
            }
            return;
        }
        setAccessable(true);
    }, [ability.rules, authState?.userProfile]);

    const updateUserAbilities = async () => {
        try {
            const response = await getMyRoleResources();
            let abilities = [];
            for (let i = 0; i < response.data.Data.length; i += 1) {
                if (response.data.Data[i].operations) {
                    abilities = [ ...abilities, ...response.data.Data[i].operations ];
                }
            }
            updateAbility(ability, abilities);
            dispatch(authActions.setUserAbilities({userAbilities: abilities}));

            if (response.data.Data?.length > 0) {
                const roles = response.data.Data.map(item => item.roleName);
                const userProfile = {
                    id: response.data.Data[0]?.id,
                    roles
                }
                dispatch(authActions.setUserProfile({userProfile}))
            }
            
        } catch (error) {

        }
    }

    if (!accessable) {
        return <PageNotFound />
    }
    return !!accessable ? props.children : <AuthRequirement {...restProps} />;
};
export default PrivateRoute;
