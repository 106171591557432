import { getSigner, getSmartContract } from 'smart-contract/common'
import governorConfig from 'smart-contract/abi/TSGovernor.json'
import ercConfig from 'smart-contract/abi/IERC20.json'
import {getProposalInfo} from 'apis/apivote'
import { ethers } from 'ethers';

export async function createProposalId(addressGovernor, addressReceive, tokenBuyAddress, amount, description, fee, ownerAddress, isRefund, totalPercent, configBlock) {
    let ABI = [
        "function transfer(address to, uint amount)"
    ];
    const contractGovernor = getSmartContract(addressGovernor, governorConfig.abi, getSigner());
    let iface = new ethers.utils.Interface(ABI);
    const encodeData = iface.encodeFunctionData("transfer", [addressReceive, ethers.utils.parseEther(amount.toFixed(2))]);
    const encodeDataFee = iface.encodeFunctionData("transfer", [ownerAddress, ethers.utils.parseEther(fee.toFixed(2))]);

    const remainingAmount = ethers.utils.parseEther(amount.toFixed(2));
    //trên smartcontract đang fix là 1.000.000
    const remainingPercent = (totalPercent / 100) * 1000000;
    const feeUInt = ethers.utils.parseEther(fee.toFixed(2));

    const rsSmart = await contractGovernor["propose(address[],uint256[],bytes[],string,uint256,uint256,bool,uint256,uint256,uint256)"]([tokenBuyAddress, tokenBuyAddress], [0, 0], [encodeData, encodeDataFee], description, configBlock.registerBlock, configBlock.voteBlock, isRefund, remainingPercent, remainingAmount, feeUInt);
    await rsSmart.wait()
    const descriptionHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(description));//ethers.utils.keccak256(ethers.utils.toUtf8Bytes("Vote Demo"));
    const proposeId = await contractGovernor["hashProposal(address[],uint256[],bytes[],bytes32)"]([tokenBuyAddress, tokenBuyAddress], [0, 0], [encodeData, encodeDataFee], descriptionHash);
    return proposeId ? proposeId.toString() : null;
}
export async function excutePropose(addressGovernor, proposalId) {
    const contractGovernor = getSmartContract(addressGovernor, governorConfig.abi, getSigner());
    const rs = await getProposalInfo({
        proposalId: proposalId
    });
    const arr = rs?.data?.Data;
    const objProposal = (arr && arr.length > 0) ? arr[0] : null;
    if (objProposal) {
        try {
            const descriptionHashToByte = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(objProposal.Description));
            const callData = [
                "0x" + objProposal.Calldatas[0],
                "0x" + objProposal.Calldatas[1]
            ]
            return await contractGovernor.execute(objProposal.Targets, objProposal.Values, callData, descriptionHashToByte);
        } catch (err) {
            console.log(err);
        }
    }
    return null;
}

export async function getBalanceGovernor(addressTokenBuy, addressGovernor) {
    try {
        const contractTokenBuy = getSmartContract(addressTokenBuy, ercConfig.abi, getSigner());
        const rsSmart = await contractTokenBuy.balanceOf(addressGovernor);
        var rs = ethers.utils.formatEther(rsSmart);
        rs = parseFloat(rs);
        return rs;
    } catch (err) {
        console.log(err);
    }
    return 0;
}
export async function getStatus(addressGovernor, proposalId) {
    try {
        const contractGovernor = getSmartContract(addressGovernor, governorConfig.abi, getSigner());
        return await contractGovernor.state(proposalId);

    } catch (err) {
        console.log(err);
    }
    return 0;
}
export async function checkVote(addressGovernor, proposalId) {
    try {
        const contractGovernor = getSmartContract(addressGovernor, governorConfig.abi, getSigner());
        const rs = await contractGovernor.checkVote(proposalId);
        await rs.wait();
        return true;
    } catch (err) {
        console.log(err);
    }
    return false;
}
export async function getRemainingInfo(addressGovernor) {
    try {
        const contractGovernor = getSmartContract(addressGovernor, governorConfig.abi, getSigner());
        const rs = await contractGovernor.getRemainingInfo();
        const remainingPercent = (rs[0].toNumber() * 100) / 1000000;
        const remainingAmount = ethers.utils.formatEther(rs[1]);
        return [parseFloat(remainingPercent), parseFloat(remainingAmount)];
    } catch (err) {
        console.log(err);
    }
    return null;
}
