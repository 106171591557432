import axios from 'axios'
import { getApiUrl } from 'utils/common'
import { getAuthorizationHeader, getDefaultHeader } from 'utils/common'
export function getProject(data) {
    var url = getApiUrl()+'/Projects/Project_Information/pub/GetList';
    const config = getDefaultHeader()
    return axios.post(url, data, config)
}
export function updateProject(data) {
    var url = getApiUrl()+'/Projects/Project_Step01/auth/UpdateStatus';
    const config = getAuthorizationHeader()
    return axios.put(url, data, config)
}