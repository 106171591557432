import { createSlice } from '@reduxjs/toolkit';
export const connectWalletSlice = createSlice({
  name: "user",
  initialState: {
    address:""
  },
  reducers: {
    connected: (state, action) => {
      state.address = action.payload.address;
    },
    disconnect: (state, action) => {
      state.address = "";
    }
  },
});

export const { connected, disconnect } = connectWalletSlice.actions;

export const address = (state) => state.address; // What is that ? and why is it state.user and then a third user Object ???

export default connectWalletSlice.reducer;