/** @format */

import { Checkbox, Form, Input, message, Spin } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import API from "apis/apiconfig";
import { useParams, useNavigate } from 'react-router-dom';
import BaseToolBarDetail from "components/common/tool-bar/BaseToolBarDetail";
const VentureCapitalTypeDetail = (props) => {
    const [form] = Form.useForm();
    const { id } = useParams();
    const [masterData, setMasterData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const optionName = "venture-capital-types";
    const navigate = useNavigate();
    const onFinish = async (values) => {
        const saveObj = { ...masterData };
        const keys = Object.keys(values);
        keys.map(key => saveObj[key] = values[key]);
        if (!id || id !== 'add-new') {
            try {
                const rs = await API.updateOptionById(
                    id,
                    saveObj,
                    optionName
                );
                navigate('/venture-capital-types')
            } catch (error) {
                message.success(error);
            }
        } else {
            try {
                const rs = await API.createOptionByType(saveObj, optionName);
                navigate('/venture-capital-types')
            } catch (error) {
                message.success(error);
            }
        }
    };
    const loadData = async () => {
        const res = await API.getOptionById(optionName, id);
        setMasterData(res.Data);
        setDataLoaded(true);
    }
    const save = () => {
        form.submit();
    }
    const cancel = () => {
        navigate('/venture-capital-types')
    }
    useEffect(() => {
        loadData();
    }, [id]);

    return (
        <>
            {
                dataLoaded ?
                    <>
                        <BaseToolBarDetail save={save} cancel={cancel} />
                        <div style={{ padding: '20px 20px 0px 20px' }}>
                            <Form
                                form={form}
                                name="basic"
                                layout="vertical"
                                initialValues={masterData}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    label="Code"
                                    name="code"
                                    rules={[{ required: true, message: "Please input your code!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: "Please input your name!" }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    rules={[{ max: 255, message: "Max length 255!" }]}
                                >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item
                                    name="inactive"
                                    rules={[{ max: 255, message: "Max length 255!" }]}
                                >
                                    <Checkbox >Inactive</Checkbox>
                                </Form.Item>
                            </Form>
                        </div>
                    </>
                    : <div><Spin size="large"></Spin></div>
            }
        </>
    );
};

export default VentureCapitalTypeDetail;
