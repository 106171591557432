import { all, put, takeLeading } from 'redux-saga/effects';
import { authActions } from 'redux/reducers/authSlice';
import { removeCookie } from 'utils/common';
import { ACCESSTOKEN, REFRESH_TOKEN } from 'utils/constants';


function* logout() {
    yield put({ type: "ROOT_REDUCER_RESET" });
    removeCookie(ACCESSTOKEN);
    removeCookie(REFRESH_TOKEN);
    setTimeout(() => window.location.replace('/login'), 500);
}


export default function* authSaga() {
  try {
    yield all([
      yield takeLeading(authActions.logout, logout),
    ])
  } catch (err) {
    console.log('authSaga', err)
  }
}