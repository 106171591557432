import { Checkbox, DatePicker, Select } from "antd";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { filterParamOperations, filterParamTypes } from "utils/constants";
const { Option } = Select;

const { RangePicker } = DatePicker;

const DateControlFilter = forwardRef((props, ref) => {
    const [checked, setChecked] = useState(props.checked);
    const [operation, setOperation] = useState(props.operation); // from, to, between
    const [values, setValues] = useState(props.value);

    const checkChange = (evt) => {
        setChecked(evt.target.checked);
    }

    useImperativeHandle(ref, () => ({
        getData() {
            if (checked) {
                return {
                    operation,
                    value: values?.length === 1 ? values[0] : 0,
                    key: props?.columnName,
                    type: filterParamTypes.DATE,
                    from: values?.length > 1 ? values[0] : 0,
                    to: values?.length > 1 ? values[1] : 0,
                }
            }
            return null
        },
    }))

    useEffect(() => {
        setChecked(false);
        setOperation(null);
        setValues(null);
    }, [props.time]);

    const onSingleDateChange = (date, dateString) => {
        const dateTimestamp = new Date(dateString).getTime();
        setValues([dateTimestamp]);
    }

    const onRangeDateChange = (dates, datesString) => {
        const fromDate = new Date(datesString[0]).getTime();
        const toDate = new Date(`${datesString[1]} 23:59:59`).getTime();
        setValues([fromDate, toDate]);
    }

    const dateInput = () => {
        if (operation === filterParamOperations.BETWEEN) {
            return (
                <RangePicker
                    format="YYYY/MM/DD"
                    onChange={onRangeDateChange}
                />
            );
        }

        return (
            <DatePicker onChange={onSingleDateChange} width="100" />
        );
    }

    return <div className="filter-control">
        <Checkbox checked={checked} onChange={checkChange} >{props.title}</Checkbox>
        {
            checked ? <div className="f-controls">
                <Select value={operation} onChange={(val) => setOperation(val)} style={{ width: 60 }}>
                    <Option title="From" key={filterParamOperations.FROM}>{"[..."}</Option>
                    <Option title="End with" key={filterParamOperations.TO}>{"...]"}</Option>
                    <Option title="Between" key={filterParamOperations.BETWEEN}>{"[]"}</Option>
                </Select>
                <div style={{ flex: 1, marginLeft: 10 }}>
                    {dateInput()}
                </div>
            </div> : null
        }
    </div>
});

export default DateControlFilter;