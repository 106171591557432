import { Modal, Alert } from 'antd'
import metamask from 'assets/img/wallets/metamask.png'
import walletconnect from 'assets/img/wallets/walletconnect.png'
import WalletItem from 'components/common/connect-wallet/WalletItem'
import { useState } from 'react';
function WalletList(props) {
    const [message, setMessage] = useState("");
    const wallets = [
        { icon: metamask, text: "MetaMask", id: 'metamask' },
        { icon: walletconnect, text: "WalletConnect", id: 'walletconnect' }
    ]
    function handleCancel() {
        props.onClosed(false)
    }
    function handleOk(walletId) {
        var allowClose=true;
        if (walletId === "metamask") {
            if (typeof window.ethereum === 'undefined')
            {
                allowClose=false;
                setMessage("Metamask is not installed.");
            }
        }
        if(allowClose)
            props.onClosed(walletId);
    }


    return <Modal width="300px" title="Connect to a wallet" visible={props.visible} onCancel={handleCancel} footer={null}>
        <>
            <div className='wallet-list'>
                {
                    wallets.map(item => {
                        return <WalletItem key={item.id} {...item} onItemClick={(id) => { handleOk(id) }} />
                    })
                }
            </div>
            <div className='wallet-notify'>
                {message ? <Alert message={message} type="error" showIcon /> : null}
            </div>
        </>
    </Modal>
}
export default WalletList