/** @format */

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import API from "apis/apiconfig";
import BaseFilter from "components/common/controls/BaseFilter";
import BaseToolBar from "components/common/tool-bar/BaseToolBar";
import { Can } from "config/Can";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { abilityActions, filterParamTypes, resourceNames } from "utils/constants";

const FundraisingOption = () => {
  const optionName = "fundraising-stages";
  const resourceName = resourceNames.fundraisingStages;
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);

  const initialFilterRequest = {
    pageIndex: 0,
    pageSize: 10,
    params: []
  };
  const [ filterRequest, setFilterRequest ] = useState(initialFilterRequest);

  useEffect(() => {
    getDataOption();
  }, [filterRequest]);

  const getDataOption = async () => {
    setLoading(true);
    const res = await API.filter(optionName, filterRequest);
    setTotalRecord(res.TotalRecords);
    setListData(res.Data);
    setLoading(false);
  };

  useEffect(() => {
    getDataOption();
  }, []);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Action",
      width: 140,
      render: (render, record) => (
        <div>
          <Can I={abilityActions.update} a={resourceName}>
            <Button
              title="Edit"
              icon={<EditOutlined />}
              onClick={() => navigate("/" + optionName + "/" + record.id)}
              style={{ margin: "0 10px" }}
              type="link"
            ></Button>
          </Can>
          <Can I={abilityActions.delete} a={resourceName}>
            <Button
              title="Delete"
              icon={<DeleteOutlined style={{ color: "red" }} />}
              style={{ margin: "0 10px" }}
              type="link"
            ></Button>
          </Can>
        </div>
      ),
    },
  ];

  const filterParams = [
    { checked: false, columnName: "name", type: filterParamTypes.TEXT, columnType: 0, title: "Name" },
    { checked: false, columnName: "code", type: filterParamTypes.TEXT, columnType: 0, title: "Code" },
    { checked: false, columnName: "cDate", type: filterParamTypes.DATE, columnType: 0, title: "Created date" },
    { checked: false, columnName: "lDate", type: filterParamTypes.DATE, columnType: 0, title: "Modified Date" },
  ];

  const handleFilter = (params) => {
    const newFitlerRequest = { ...filterRequest };
    newFitlerRequest.params = params;
    setFilterRequest(newFitlerRequest);
  }

  const handlePagination = (event) => {
    setCurrentPage(event);
  };
  const refresh = () => {
    setCurrentPage(1);
    getDataOption();
  };

  const addNew = () => {
    navigate(`/${optionName}/add-new`);
  };

  return (
    <>
      <BaseToolBar
          resourceName={resourceName}
          addNew={addNew}
          refresh={refresh}
        />
      <div className='box-list'>
        <BaseFilter filterParams={filterParams} onFilter={(params) => handleFilter(params)} />
        <div style={{ flex: 1, borderLeft:'1px solid #ddd' }}>
          <div className='flex-scroll-wrapper'>
            <div className='flex-scroll-content'>
              <Table
                dataSource={listData}
                columns={columns}
                loading={loading}
                pagination={{
                  onChange: handlePagination,
                  pageSize: 10,
                  total: totalRecord,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};
export default FundraisingOption;
