/** @format */

import { customAxios } from "utils/customAxios";

const serverEndpoint = process.env.REACT_APP_API_URL;

export const handleResponse = (res) => {
  const data = res?.data?.data;
  return data;
};

export const handleError = (err) => {
  console.error(err);
  throw err;
};

export default {
  // option controller
  createOptionByType: async (data, option) => {
    const res = await customAxios({
      method: "post",
      url: `${serverEndpoint}/admin/${option}/auth/create`,
      data: data,
    });
    return res.data;
  },
  getOptionByType: async (option, body) => {
    const res = await customAxios({
      method: "get",
      url: `${serverEndpoint}/admin/${option}/auth/pagination`,
      data: body,
    });
    return res.data;
  },
  getOptionById: async (option, id) => {
    const res = await customAxios({
      method: "get",
      url: `${serverEndpoint}/admin/${option}/auth/${id}`,
    });
    return res.data;
  },
  updateOptionById: async (id, data, option) => {
    const res = await customAxios({
      method: "put",
      url: `${serverEndpoint}/admin/${option}/auth/${id}`,
      data: data,
    });
    return res.data;
  },
  filter: async (option, request) => {
    const res = await customAxios({
      method: "post",
      url: `${serverEndpoint}/admin/${option}/auth/filter`,
      data: request,
    });
    return res.data;
  }
};
