import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  userProfile: {}, // {id, roles: ["ROLE_A, ROLE_B"]}
  userAbilities: [], // [{action, name}]
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, { payload }) => {
      state.accessToken = payload;
    //   state.userProfile = payload.userProfile;
    },
    deauthenticated: (state) => {
      state.accessToken = "";
      state.userProfile = {};
    },
    setUserAbilities: (state, action) => {
      state.userAbilities = action.payload.userAbilities;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload.userProfile
    },
    logout: (state, action) => {
    }
  },
});

// Action creators
export const authActions = authSlice.actions;

// Selector
export const authSelector = (state) => state;

export default authSlice.reducer;
