import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  listChain: [],
  listContract: [],
  switchChainId:'',
  currentChainId:'',
  switchTitle:'',
  switchDescription:'',
  showConfirmSwitchChain:false
}

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    updateListChain: (state, { payload }) => {
      state.listChain = payload
    },
    updateListContract: (state, { payload }) => {
      state.listContract = payload
    },
    updateSwitchChainId:(state, { payload }) => {
      const {chainId,currentChainId,title,description} = payload;
      state.switchChainId = chainId
      state.currentChainId = currentChainId
      state.switchTitle = title
      state.switchDescription = description
      state.showConfirmSwitchChain = true
    },
    closeConfirmSwitchChain:(state) => {
      state.showConfirmSwitchChain = false
    }
  },
})

// Action creators
export const { updateListChain, updateListContract,closeConfirmSwitchChain,updateSwitchChainId } = networkSlice.actions

// Selector
export const networkSelector = (state) => state.network

export default networkSlice.reducer
