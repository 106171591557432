import Cookies from 'universal-cookie'
import { refreshToken } from 'apis/apiwallet'
import { ACCESSTOKEN } from './constants'
import { ethers } from 'ethers'
import chains from './chains.json'
import store from 'redux/stores/index'

export function getApiUrl() {
  return process.env.REACT_APP_API_URL;
}
export function getAdminApiUrl() {
    return `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_PREFIX_ADMIN_API_URL}`;
}
export function getPrefixStorageApiUrl() {
  return process.env.REACT_APP_PREFIX_STORAGE_API_URL;
}
export function getPrefixIdentifyApiUrl() {
  return process.env.REACT_APP_PREFIX_IDENTIFY_API_URL;
}
export function getPrefixAdminApiUrl() {
  return process.env.REACT_APP_PREFIX_ADMIN_API_URL;
}
export function getProjectAddressContract() {
  return process.env.REACT_APP_CONTRACT_TSPROJECT_ADDRESS;
}
export function getSaleAddressContract() {
  return process.env.REACT_APP_CONTRACT_SALE_ADDRESS;
}
export function getUtilsAddressContract() {
  return process.env.REACT_APP_CONTRACT_UTILS_ADDRESS;
}
export function getOwnerAddress() {
  return process.env.REACT_APP_CONTRACT_OWNER_ADDRESS
}
export function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  }
}
export function setCookie(key, value, option) {
  const cookies = new Cookies()
  cookies.set(key, value, option)
}
export function getCookie(key) {
  const cookies = new Cookies()
  return cookies.get(key)
}
export function removeCookie(key) {
  const cookies = new Cookies()
  cookies.remove(key)
}
export function clearReduxPersist() {
  localStorage.removeItem("persist:root");
}
export function getAuthorizationHeader() {
  const accessToken = getCookie(ACCESSTOKEN)
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  }
  return config
}
export function getDefaultHeader() {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  return config
}
export function formartDateTime(momentDate) {
  return momentDate.format('YYYY-MM-DD HH:mm:ss')
}
export async function apiCall(callback, args) {
  var res = await doCallBack(callback, args)
  if (res.status === 401) {
    const accessToken = await refreshToken()
    if (accessToken) {
      setCookie(ACCESSTOKEN, accessToken)
      res = await doCallBack(callback, args)
    } else {
      removeCookie(ACCESSTOKEN)
    }
  }
  return res
}
function doCallBack(callback, args) {
  var res = null
  res = callback
    .apply(this, args)
    .then((result) => (res = result))
    .catch((err) => (res = err.response))
  return res
}
/**
 * Setup request interceptor to set access token
 * @param {*} axiosInstance axios instance including default instance (axios)
 * @returns that instance
 */
export function setupAxiosInterceptor(axiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const accessToken = getCookie(ACCESSTOKEN)
      if (accessToken) {
        config.headers['Authorization'] = accessToken
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )
  return axiosInstance
}

/**
 * Extract Data from common API response body structure
 * @param data {*} API response body
 * @returns
 */
export const dStarterApiDataExtractor = (data) => {
  const { ResultCode, Message, Timestamp, Data, ...rest } = data
  if (ResultCode == '200') {
    return { OK: true, Message, Timestamp, Data, ...rest }
  } else {
    return { OK: false, Code: ResultCode, ResultCode, Message, Data, ...rest }
  }
}
/**
 * Catch error and form a common structure
 * @param {*} error
 * @returns
 */
export const dStarterApiErrorHandler = (error) => {
  if (error.ResultCode) {
    return error
  } else {
    return { OK: false, Code: -1, ResultCode: -1, Message: error.message, Data: error }
  }
}


export async function switchNetwork(chainId) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    // const chainParam = chains.find(x => x.chainId == chainId);
    // if (chainParam) {
    //   await window.ethereum.request({
    //     method: 'wallet_addEthereumChain',
    //     params: [
    //       chainParam
    //     ],
    //   });
    // }
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        const chainParam = chains.find(x => x.chainId == chainId);
        if (chainParam) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              chainParam
            ],
          });
        }
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
}
export function getCurrentProvider() {
  return new ethers.providers.Web3Provider(window.ethereum);
}
export const requestSwitchNetwork = async (chain) =>
  new Promise(async (resolve, reject) => {
    const chainIds = ethers.utils.hexValue(Number(chain.chainID));
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIds }],
      })
      return resolve(true)
    } catch (error) {
      // This error code indicates that the chain has not been added to MetaMask
      if (error.code === 4902) {
        const chainParam = {
          chainId: chainIds,
          chainName: chain.chainName,
          rpcUrls: chain.rpcUrls,
          nativeCurrency: chain.nativeCurrency,
          blockExplorerUrls: chain.blockExplorerUrls
        }
        try {
          const rs = await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [chainParam],
          })

          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (chainId !== chain.chainID) {
            return reject({ messsage: 'User rejected the request switch chain' })
          }
          return resolve(true)
        } catch (error) {
          return reject(error)
        }
      }
      return reject(error)
    }
  })
export const getContractByCode = (code,currentChainId) => {
  var result = "";
  const listContract = store.getState().network.listContract
  if (listContract ) {
    const res = listContract?.find((item) => item.code === code && item.chain && item.chain.chainID === currentChainId)
    result = res.address
  }
  if (!result) console.log("Can not get smart contract address by code " + code);
  return result;
}