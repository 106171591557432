import {
  FileDoneOutlined,
  UsergroupAddOutlined,
  FolderOutlined,
  FileOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  AppstoreOutlined,
  SnippetsOutlined,
  SettingOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { resourceNames } from "utils/constants";

export const MENU_ITEMS = [
  {
    name: "Document",
    resourceName: resourceNames.document,
    icon: <FolderOutlined />,
    subMenus: [
      {
        name: "White paper",
        resourceName: resourceNames.whitePaper,
        icon: <FileOutlined />
      },
      {
        name: "Blog",
        resourceName: resourceNames.blog,
        icon: <FormOutlined />
      },
      {
        name: "FAQ",
        resourceName: resourceNames.faq,
        icon: <QuestionCircleOutlined />
      }
    ]
  },
  {
    name: "Account",
    resourceName: resourceNames.account,
    icon: <UserOutlined />
  },
  {
    name: "Pool",
    resourceName: resourceNames.pool,
    icon: <AppstoreOutlined />,
    subMenus: [
      {
        name: "Summary pool",
        resourceName: resourceNames.summaryPool,
        icon: <SnippetsOutlined />
      },
      {
        name: "Create vote",
        resourceName: resourceNames.createVote,
        icon: <UsergroupAddOutlined />
      },
    ]
  },
  {
    name: "Buidl",
    resourceName: resourceNames.buidl,
    icon: <ProjectOutlined />,
    subMenus: [
      {
        name: "Approve project",
        resourceName: resourceNames.approveProject,
        icon: <FileDoneOutlined />
      }
    ]
  },
  {
    name: "Dictionary",
    resourceName: "dictionary",
    icon: <ProjectOutlined />,
    subMenus: [
      {
        name: "Fundraising Stages",
        resourceName: resourceNames.fundraisingStages,
        icon: <FileDoneOutlined />
      },
      {
        name: "Interested Fields",
        resourceName: resourceNames.interestedFields,
        icon: <FileDoneOutlined />
      },
      {
        name: "Chains",
        resourceName: resourceNames.chains,
        icon: <FileDoneOutlined />
      },
      {
        name: "Hash Tags",
        resourceName: resourceNames.hashTags,
        icon: <FileDoneOutlined />
      },
      {
        name: "Currency",
        resourceName: resourceNames.currencies,
        icon: <FileDoneOutlined />
      },
      {
        name: "Venture Capital Type",
        resourceName: resourceNames.ventureCapitalTypes,
        icon: <FileDoneOutlined />
      },
      {
        name: "Interested Stage",
        resourceName: resourceNames.interestedStages,
        icon: <FileDoneOutlined />
      },
      {
        name: "Smart Contract",
        resourceName: resourceNames.contractAddresses,
        icon: <FileDoneOutlined />
      }
    ]
  },
  {
    name: "Authorizations",
    resourceName: resourceNames.authorizations,
    icon: <SettingOutlined />,
    subMenus: [
      {
        name: "Role",
        resourceName: resourceNames.roles,
        icon: <FileDoneOutlined />
      },
      {
        name: "Resource",
        resourceName: resourceNames.resources,
        icon: <FileDoneOutlined />
      }
    ]
  },
  {
    name: "Setting",
    resourceName: resourceNames.setting,
    icon: <SettingOutlined />,
    subMenus: []
  },
]