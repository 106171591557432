import axios from 'axios';
import { getApiUrl, getCookie, getPrefixStorageApiUrl } from 'utils/common';
import { getDefaultHeader } from 'utils/common';
import { ACCESSTOKEN } from 'utils/constants';

export function uploadFile(data) {
    var url =  `${getApiUrl()}/${getPrefixStorageApiUrl()}/Upload/auth/UploadFiles`;

    const formData = new FormData()
    formData.append('file', data);
    const accessToken = getCookie(ACCESSTOKEN);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: accessToken,
        }
    };
    return axios.post(url, formData, config);
}
